export class EmailModel {
  subject: string;
  name: string;
  email: string;
  phone: string;
  message: string;
  address: string;
  option: string;
  type: number;
  datetime: number;
  isNew: boolean;
  isReply: boolean;
  isMark: boolean;
  isDelete: boolean;
  isSent:boolean;
  groupId: string;

  constructor(){
    this.subject = "";
    this.name = "";
    this.email = "";
    this.phone = "";
    this.message = "";
    this.address = "";
    this.option = "";
    this.type = 0;
    this.datetime = 0;
    this.isNew = true;
    this.isReply = false;
    this.isMark = false;
    this.isDelete = false;
    this.isSent = false;
    this.groupId = "";
  }
}
