import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Http, Response, Headers, RequestOptions, URLSearchParams } from '@angular/http';

import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { AngularFirestore, AngularFirestoreDocument } from 'angularfire2/firestore';
import { AngularFireDatabaseModule, AngularFireDatabase, AngularFireList, AngularFireObject } from 'angularfire2/database';
import * as firebase from 'firebase/app';

import { OrderModel } from './order.model';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  ordersRef: AngularFireList<OrderModel> = null;

  constructor(
  	private afData: AngularFireDatabase,
  ) {
  	this.ordersRef = afData.list('/orders');
  }

  getOrdersList(): AngularFireList<OrderModel> {
    return this.ordersRef;
  }

  getOrder(id): AngularFireList<OrderModel> {
    return this.afData.list('/orders/'+id);
  }

  updateOrder(key, dev) {
    this.ordersRef.set(key, dev);
  }

  createOrder(orders: OrderModel): void {
    this.ordersRef.push(orders);
  }

  deleteOrder(key: string): void {
    this.ordersRef.remove(key).catch(error => this.handleError(error));
  }

  private handleError(error) {
    console.log(error);
  }
}
