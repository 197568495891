import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router'; 
import { Observable } from 'rxjs'; 
import { Subject } from 'rxjs/Subject';

/// Notify users about errors and other helpful stuff
export interface Msg {
  content: string;
  style: string;
}

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  private subject = new Subject<any>();

  constructor() { }

  private _msgSource = new Subject<Msg | null>();

  msg = this._msgSource.asObservable();

  update(content: string, style: 'error' | 'info' | 'success') {
    const msg: Msg = { content, style };
    this._msgSource.next(msg);
  }

  clear() {
    this._msgSource.next(null);
  }


  confirm(message: string,siFn:()=>void,noFn:()=>void){
    this.setConfirmation(message,siFn,noFn);
  }
  
  setConfirmation(message: string,siFn:()=>void,noFn:()=>void) {
    let that = this;
    this.subject.next({ type: "confirm",
        text: message,
        siFn:
        function(){
            that.subject.next(); //this will close the modal
            siFn();
        },
        noFn:function(){
            that.subject.next();
            noFn();
        }
     });
   }

  getMessage(): Observable<any> {
     return this.subject.asObservable();
  }

}
