import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, Params } from '@angular/router';
import { Http, Response, Headers } from '@angular/http';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, first } from 'rxjs/operators';

import { AlertService, AuthenticationService, MysqlService,
          CategoryModel, CategoryService,
          CompanyModel, CompanyService,
          ProductModel, ProductService,
          ShippingRateModel, ShippingRateService, 
          PaymentModel, PaymentService,
          WishListModel, WishlistService,
          md5, LibsService, LocalData,
          UserService, UserModel } from './_services';
import * as $ from 'jquery';
import * as firebase from 'firebase/app';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  isMobile = false;
  screenWidth = 0;
  company: CompanyModel = new CompanyModel();
  menus = this._localDB.menus;


  currentRoute = '';
  isShowULTV = false;
  idLoaded = false;
  isLogged = false;
  alertMessage = '';
  sayHello = '';
  title = 'DOLE CONNECTIONS APP';
  uploadUrl = this._localDB.uploadUrl;
  
  isValidFirstName =  true;
  isValidLastName = true;
  isValidPhone = true;
  isValidEmail = true;
  isValidPassword = true;
  currentUser: UserModel = new UserModel();
  temporaryPassword = '';
  loginForm: FormGroup;
  medias = [];
  loginMessage = 'Let\'s make today a great day!';
  categories = [];
  isActive = false;
  saveSearchForm: FormGroup;
  isValidSSName = true;
  itemsOrder = [];
  totalItems = 0;
  products = [];
  shippingRates = [];
  paymentTypes = [];
  userWishlistes = [];
  wishlist = new WishListModel();

  constructor(
    public router: Router,
    private formBuilder:FormBuilder,
    private http: Http,
    private _localDB: LocalData,
    private _libs: LibsService,
    private _mysql: MysqlService,
    private userService: UserService,
    private companyService: CompanyService,
    private authService: AuthenticationService,
    private productService: ProductService,
    private shippingRateService: ShippingRateService,
    private paymentService: PaymentService,
    private categoryService: CategoryService,
    private wishlistService: WishlistService,
  ){

    // console.log(this.currentUser);
    this.sayHello = this._libs.sayHelloString();
    this.company = this._libs.getLocalStorageItem('company', 'object');
    // console.log(this.company);
    this.products = this._libs.getLocalStorageItem('products', 'array');
    this.shippingRates = this._libs.getLocalStorageItem('shippingRates', 'array');
    this.paymentTypes = this._libs.getLocalStorageItem('paymentTypes', 'array');
    this.userWishlistes = this._libs.getLocalStorageItem('wishlistes', 'array');

    this.idLoaded = this._libs.getLocalStorageItem('idLoaded', 'boolean');
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd ) {
        window.scrollTo(0, 0);

        // console.log(this.company['id']);
        if (this.company['id'] == undefined){
        	this.getCompanyInfo();
        }
        if (this.shippingRates.length == 0){
          this.getShippingRatesList();
        }
        if (this.paymentTypes.length == 0){
          this.getPaymentsList();
        }
        if (this.products.length == 0){
          this.getProductsList();
        }else{
          let tempProds = this.products.filter(ft => ft['isFeature'] == true);
          this._libs.setLocalStorageItem('featureItems', tempProds);
        }
        this.currentUser = this._libs.getLocalStorageItem('currentUser', 'object');
        this.itemsOrder = this._libs.getLocalStorageItem('itemsOrder', 'array');

        // console.log(this.currentUser['email']);
        if (this.currentUser['email'] != undefined){
          this.isLogged = true;
          $('.sign-in').html('<a href="#/account" class="user c-btn btn-no-focus c-btn-header btn btn-sm c-btn-border-1x c-btn-login c-btn-square c-btn-uppercase c-btn-sbold">\
                    <img src='+this.uploadUrl + this.currentUser['avatar']+' class="user-avatar">\
                    '+ this.currentUser['firstname']+' '+this.currentUser['lastname']+'\
                  </a>')
          // console.log(this.itemsOrder);
          this.checkShoppingCart();
          if (this.userWishlistes.length == 0){
            this.getWishListesList();
          }else{
            this.checkWishlistes();
          }
        }else{
          this.isLogged = false;
          this.checkShoppingCart();
        }
        
        this.currentRoute = this._libs.getCurrentRoute(event.url);
        
        // console.log(this.currentRoute);
        $('body').removeClass('product products solutions support partners contact account dashboard cart checkout order').addClass(this.currentRoute);
        // $('header').addClass('c-layout-header-4').removeClass('c-layout-header-2');
        $('body').removeClass('c-layout-header-fullscreen dashboard favorite savesearch openhouse transactions'); //.addClass('c-bg-grey');
        $('.c-topbar').addClass('c-topbar-light').removeClass('c-topbar-dark');
      }
    });
  }  

  checkShoppingCart(){
    if (this.itemsOrder.length > 0){
      this.totalItems = 0;
      $('.c-cart-number, #c-cart-menu').removeClass('hide');
      for (var i = 0; i < this.itemsOrder.length; i++) {
        this.totalItems += this.itemsOrder[i]['qty'];
      }
    }else{
      $('.c-cart-number, #c-cart-menu').addClass('hide');
    }
  }

  checkWishlistes(){
    // console.log(this.userWishlistes);
    for (var i = 0; i < this.userWishlistes.length; ++i) {
      for (var j = 0; j < this.products.length; ++j) {
        if (this.products[j]['id'] == this.userWishlistes[i]['itemId']){
          this.products[j]['isWishList'] = true;
          if (this.currentRoute == 'products'){
            $('.pro'+this.userWishlistes[i]['itemId']).addClass('c-btn-red').removeClass('btn-default');
          }
        }
      }
      // console.log('.add'+this.userWishlistes[i]['itemId']);
    }
    this._libs.removeLocalStorageItem('products');
    this._libs.setLocalStorageItem('products', this.products);
    // console.log(this.products);
  }

  phoneFormat(e){
    // console.log(Number(e));
    var temp = this._libs.formatPhoneNumber(e)
    // console.log(temp);
    if (temp != null || temp != ''){
      this.currentUser['phone'] = temp;
    }
  }

  //Init method
  ngOnInit(){
    this.saveSearchForm = this.formBuilder.group({
      name: ['', Validators.required],
      notificationId: ['', Validators.required],
    })

    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });

    if (!this.idLoaded){
      // this.onLoginFormSubmit();
    }

    this.screenWidth = (window.screen.width);
    if (this.screenWidth < 992){
      this.isMobile = true;
    }
    if (this.screenWidth >= 992 && this.screenWidth <= 1025){
      this.isShowULTV = true;
    }

    window.onresize = () => {
      this.screenWidth = (window.screen.width);
      if (this.isShowULTV){
        this.isShowULTV = false;
      }else{
        this.isShowULTV = true;
      }
      // console.log(this.isShowULTV);
      // console.log(this.currentRoute);
      if (this.screenWidth<992){
        $('.c-topbar').removeClass('c-topbar-dark').addClass('c-topbar-light');
      }else{
        if (this.currentRoute == 'home' || this.currentRoute == ''){
          $('.c-topbar').addClass('c-topbar-dark').removeClass('c-topbar-light');
        }
      }
      if (this.currentRoute == 'home'){
      }
      // console.log(this.screenWidth)
    };

  }

  // showMegaMenu(){
  //   // console.log('app');
  //   if ($('body').hasClass('c-mega-menu-shown')){
  //     $('body').removeClass('c-mega-menu-shown');
  //     $('.c-mega-menu').removeClass('c-shown');
  //   }else{
  //     $('.c-layout-header-fixed').addClass('c-mega-menu-shown');
  //     $('.c-mega-menu').addClass('c-shown');
  //   }
  // }

  // showTopbar(){
  //   if ($('body').hasClass('c-topbar-expanded')){
  //     $('body').removeClass('c-topbar-expanded');
  //     // $('.c-mega-menu').removeClass('c-shown');
  //   }else{
  //     $('body').addClass('c-topbar-expanded');
  //     // $('.c-mega-menu').addClass('c-shown');
  //   }
  // }


  sanitizerUrl(value){
    return this._libs.getSantizeUrl(value);
  }

  ///PRODUCTs
  getProductsList(){
    this.productService.getProductsList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ id: c.payload.key, ...c.payload.val() }))
      )
    ).subscribe(products => {
      this.products = products.sort(this._libs.dynamicSortByName('name'));
      for (var i = 0; i < this.products.length; ++i) {
        this.products[i]['isWishList'] = false;  
      }
      this._libs.setLocalStorageItem('products', this.products);
      // console.log(this.products);
      let tempProds = this.products.filter(ft => ft['isFeature'] == true);
      this._libs.setLocalStorageItem('featureItems', tempProds);
    });
  }

  getProductBy(id){
    var temp = this.products.filter(ft => ft['id'] == id);
    return temp[0];
  }

  ///COMPANY
  getCompanyInfo(){
    this.companyService.getCompanyInfo().snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ id: c.payload.key, ...c.payload.val() }))
      )
    ).subscribe(company => {
      // console.log(company);
      if (company.length > 0){
        this._libs.setLocalStorageItem('company', company[0]);
    	this.company = company[0];
      }else{
        this.company = new CompanyModel();
      }
    });
  }

  ///PAYMENT
  getPaymentsList(){
    this.paymentService.getPaymentsList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ id: c.payload.key, ...c.payload.val() }))
      )
    ).subscribe(payments => {
      this.paymentTypes = payments.sort(this._libs.dynamicSortByName('name'));
      this._libs.setLocalStorageItem('paymentTypes', this.paymentTypes);
      // console.log(this.paymentTypes);
    });
  }

  /// WISHLISTES
  getWishListesList(){
    this.wishlistService.getWishlistesList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ id: c.payload.key, ...c.payload.val() }))
      )
    ).subscribe(wishlistes => {
      this.userWishlistes = wishlistes.filter(ft => ft['email'] == this.currentUser['email']);
      // console.log(this.userWishlistes);
      this._libs.setLocalStorageItem('userWishlistes', this.userWishlistes);
      this.checkWishlistes();
    });
  }

  /// SHIPPING RATES
  getShippingRatesList(){
    this.shippingRateService.getShippingRatesList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ id: c.payload.key, ...c.payload.val() }))
      )
    ).subscribe(shippingRates => {
      this.shippingRates = shippingRates.sort(this._libs.dynamicSortByName('value'));
      for (var s = 0; s < this.shippingRates.length; ++s) {
        this.shippingRates[s]['isDefault'] = false;
        if (s == 0){
          // this.shippingRateSelected = this.shippingRates[i]['id'];
          this.shippingRates[s]['isDefault'] = true;
        }
      }
      // console.log(this.shippingRates);
      this._libs.setLocalStorageItem('shippingRates', this.shippingRates);
    });
  }

  ///CATEGORY
  getCategoriesList(){
    this.categoryService.getCategoriesList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ 'id': c.payload.key, ...c.payload.val() }))
      )
    ).subscribe(categories => {
      this.categories = categories;
      // this._libs.setLocalStorageItem('categories', categories);
      // console.log(this.categories);
      this.getUserCategory();
    });
  }
  
  getULCategoryId(){
    this.categories = this.categories.filter(category => category['type'] == '3');
  }

  getUserCategory(){
    if (this.currentUser['email'] != undefined){
      var selectedCatgory = this._libs.findIdFromArrayByName(this.categories, this.currentUser['email']);
      this.getMediasByCategory(selectedCatgory);
      // console.log(this.currentRoute);
      // if (this.currentRoute == 'activate'){
      //   this._libs.go2Page('/');
      // }
    }
  }

  ///MEDIA
  getMediasByCategory(cId): void {
    this._mysql.getAllImagesByCategory(cId)
    .subscribe( (response: Response) => {
        this.medias = response.json();
        if (this.currentUser['avatar'] == "noavatar" || this.currentUser['avatar'] == "noavatar.jpg"){
          this.currentUser['avatarCode'] = this._localDB.noavatar;
        }else{
          if (this.medias.length > 0){
            // this.currentUser['avatarCode'] = this.getImgCode(this.currentUser['avatar']);
            // this.getULCategoryId();
          }
        }
        this._libs.setLocalStorageItem('currentUser', this.currentUser)
    });
  }

  getMedia(id){
    // console.log(this.medias);
    return this.medias.filter(media => media['id'] == id);
  }

  getImgCode(id){
    let avatarImg = this.getMedia(id);
    if (avatarImg != undefined){
      return (avatarImg[0]['imgCode']);
      // return this.sanitizerUrl(avatarImg[0]['imgCode']);
    }
  }

  ///SAVE SEARCH
  onSaveSearch(){
    if(!this.saveSearchForm['controls']['email']){
      this.isValidSSName = false
      return;
    }else{
      this.isValidEmail = true;
    }
  }

  ///USER: LOGIN
  onLogin(){
    // console.log(this.loginForm);
    // console.log(this.loginForm.invalid);

    if(!this.loginForm['controls']['email']){
      this.isValidEmail = false
      return;
    }else{
      if (!this._libs.isEmail(this.loginForm['controls']['email']['value'])){
        this.isValidEmail = false
        return;
      }else{
        this.isValidEmail = true;
      }
    }
    if(this.loginForm['controls']['password']['value'] == ''){
      this.isValidPassword = false
      return;
    }else{
      this.isValidPassword = true;
    }
   
    this.authService.doEmailLogin(this.loginForm['controls']['email']['value'], this.loginForm['controls']['password']['value'])
    .then(user => {
      this._libs.setLocalStorageItem('idLoaded', true);
      this.getCurrentUser(user.uid);
      // console.log('logged in')
      $('.a-add-cart, .a-add-wishlist').addClass('hide');
      $('.btn-add-cart, .btn-add-wishlist').removeClass('hide');
    }, err => {
      this._libs.setLocalStorageItem('idLoaded', false);
      // console.log(err);
      this.alertMessage = err.message;
    });
  }

  getCurrentUser(uid){
    const usersRef: firebase.database.Reference = firebase.database().ref('/users/'+uid);
    usersRef.on('value', userSnapshot => {
      var iCheckAct = userSnapshot.val()['active'];
      // console.log(iCheckAct);
      if (iCheckAct){
        this.currentUser = this._libs.convert2UserModel(userSnapshot.val());
        $('#login-form').hide();
        $('#login-form').removeClass('in');
        $('body').removeClass('modal-open');
        $('.modal-backdrop').remove();
        // this.currentUser['password'] = '';
        // this.currentUser['tempPass'] = md5(this.currentUser['password']);
        this.currentUser['id'] = this._libs.convert2NewIdString(uid);
        this._libs.setLocalStorageItem('currentUser', this.currentUser);
        // console.log(this.currentUser);
        this.isLogged = true;
        this.isActive = false;
        $('.sign-in').html('<a href="#/account" class="user c-btn btn-no-focus c-btn-header btn btn-sm c-btn-border-1x c-btn-login c-btn-square c-btn-uppercase c-btn-sbold">\
                    <img src='+this.uploadUrl + this.currentUser['avatar']+' class="user-avatar">\
                    '+ this.currentUser['firstname']+' '+this.currentUser['lastname']+'\
                  </a>')
        this.getCategoriesList();
        // console.log(this.currentRoute);
        if (this.currentUser['email'] == undefined){
          $('.a-add-cart, .a-add-wishlist').removeClass('hide');
          $('.btn-add-cart, .btn-add-wishlist').addClass('hide');
        }else{
          // console.log(this.currentUser['email']);
          // console.log(this.userWishlistes);
          let tempId = this._libs.getLocalStorageItem('tempId', 'string');
          let isHasWishList = this.userWishlistes.filter(ft => ft['itemId'] == tempId);
          if (isHasWishList.length == 0){
            this.wishlist['email'] = this.currentUser['email'];
            this.wishlist['itemId'] = tempId;
            this._libs.removeLocalStorageItem('tempId');
            console.log(this.wishlist);
            this.wishlistService.createWishlist(this.wishlist);
          }
          this.getWishListesList();
          $('.btn-add-cart, .btn-add-wishlist').removeClass('hide');
          $('.a-add-cart, .a-add-wishlist').addClass('hide');
          let items = JSON.parse(sessionStorage.getItem('itemsOrder'));
          if (items == null) items = [];
          if (items.length > 0){
            $('.c-cart-number, #c-cart-menu').removeClass('hide');
          }else{
            $('.c-cart-number, #c-cart-menu').addClass('hide');
          }
        }
        if (this.currentRoute == 'activate'){
          this._libs.go2Page('/');
        }
      }else{
        this.isLogged = false;
        this.isActive = true;
        this.loginMessage = "Please check your email and confirm your email.";
      }
    });
  }

  // getCurrentUser(id){
  //   this.userService.getUsersList().snapshotChanges().pipe(
  //     map(changes =>
  //       changes.map(c => ({'id': c.payload.key, ...c.payload.val() }))
  //     )
  //   ).subscribe(users => {
  //     this.isLogged = true;
  //     let tempUser = (users.filter(iuser => iuser['id'] == id))[0];
  //     this.currentUser = this._libs.convert2UserModel(tempUser);
  //     // console.log(this.currentUser); 
  //     this.currentUser['id'] = this._libs.convert2NewIdString(tempUser['id']),
  //     this._libs.setLocalStorageItem('currentUser', this.currentUser);
  //     this.getCategoriesList();
  //   });
  // }

  ///USER:  CREATE
  onSubmitUser(){
    let firstname : string = (<HTMLInputElement>document.getElementById("signup-firstname")).value; 
    let lastname : string = (<HTMLInputElement>document.getElementById("signup-lastname")).value; 
    let email : string = (<HTMLInputElement>document.getElementById("signup-email")).value; 
    let phone : string = (<HTMLInputElement>document.getElementById("signup-phone")).value; 
    // let occupation : string = (<HTMLInputElement>document.getElementById("occupation")).value; 
    // let password : string = (<HTMLInputElement>document.getElementById("password")).value; 
    
    if(firstname == '' || firstname == null){
      this.isValidFirstName = false
      return;
    }else{
      this.isValidFirstName = true;
    }
    if(lastname == '' || lastname == null){
      this.isValidLastName = false
      return;
    }else{
      this.isValidLastName = true;
    }
    if(email == '' || email == null){
      this.isValidEmail = false
      return;
    }else{
      if (!this._libs.isEmail(email)){
        this.isValidEmail = false
        return;
      }else{
        this.isValidEmail = true;
      }
    }
    if(phone == '' || phone == null){
      this.isValidPhone = false
      return;
    }else{
      if (!this._libs.isNumber(phone)){
        this.isValidPhone = false
        return;
      }else{
        $('#signup-phone').removeClass('ng-dirty ng-invalid ng-touched');
        this.isValidPhone = true;
      }
    }

    this.currentUser['roleId'] = '-LTYayzXFuFjQKDqvWIh';
    // this.currentUser['provider'] = 'password';
    this.currentUser['occupation'] = 'Customer';
    // console.log(this.currentUser);
    if (this.currentUser['avatar'] == undefined || this.currentUser['avatar'] == ''){
      this.currentUser['avatar'] = 'noavatar';
    }
    // let tempUser = this.currentUser;
    // tempUser['password'] = this.temporaryPassword;
    this.temporaryPassword = this.generatePassword();; 
    this.currentUser['password'] = this.temporaryPassword;
    this.currentUser = this._libs.convert2UserModel(this.currentUser);
    // console.log(this.currentUser);
    this.createFirebaseUser(this.currentUser);
  }

  generatePassword(){
    return this._libs.generatePassword();
  }

  createFirebaseUser(f){
    this.authService.doRegister(f)
    .then(newU => {
      // console.log(newU);
      f['provider'] = newU.user.providerData[0].providerId;
      this.setUser(newU.user.uid, f);
    }, err => {
      // console.log(err);
      this.alertMessage = err.message;
    })
  }

  setUser(key ,f) {
    this.userService.setUser(key, f);
    let sEmail = {
          'email': f['email'],
          'password': this.temporaryPassword,
          'name': f['firstname'] +' '+f['lastname'],
          'uid': key
      }
    let category = {name: f['email'], type: '2'}
    this.categoryService.createCategory(category);
    this.emailNewAccount(sEmail);
    this.currentUser = new UserModel();
  }


  emailNewAccount(params){
    this._mysql.emailNewAccount(params).subscribe(res => {
      let result = res;
      if (res['data']['result'] == 'OK'){
        this.alertMessage = res['data']['message'];
        // setTimeout(() => {
        //   this.alertMessage = '';
        // }, 3000);
        // this._libs.go2Page('users');
      }
    });
  }
}
