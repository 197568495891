import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { LocalData, LibsService,  MysqlService, PagerService, md5,
         PriceRangeModel, PricerangeService,
         MediaService, MediaModel,
         ProductModel, ProductService, 
         UserModel, UserService,
         OrderModel, OrderService,
         VideoModel, VideoService,
         ShippingRateModel, ShippingRateService, 
         CategoryService, CategoryModel,
         } from '../../_services';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css'] 
})
export class CheckoutComponent implements OnInit {
  currentUser = new UserModel()
  itemsOrder = [];
  states = this._localDB.states;
  total = 0;
  subtotal = 0;
  // shippingRate = this._localDB.shippingRate;
  errorMessageBillingAddress = '';
  errorMessageShippingAddress = '';
  errorMessageOrder = '';
  paymentTypes = []; //this._localDB.paymentTypes;
  order = new OrderModel();
  orderConfirm = false;
  updateUser = false;
  orders = [];
  shippingRates = [];
  shippingRateSelected = '';

  constructor(
  	private _localDB: LocalData,
    private _libs: LibsService,
    private userService: UserService,
    private orderService: OrderService,
    private shippingRateService: ShippingRateService,
  ) { 
  	this.currentUser = this._libs.getLocalStorageItem('currentUser', 'array');
    this.itemsOrder = this._libs.getLocalStorageItem('itemsOrder', 'array');
    this.orders = this._libs.getLocalStorageItem('orders', 'array');
    this.shippingRates = this._libs.getLocalStorageItem('shippingRates', 'array');
    this.paymentTypes = this._libs.getLocalStorageItem('paymentTypes', 'array');
  }

  ngOnInit() {
  	// console.log(this.currentUser);
  	// console.log(this.itemsOrder);
    if (this.shippingRates.length == 0){
      this.getShippingRatesList();
    }else{
      this.step1();    	
    }
  }

  step1(){
    let tempSRId = this.shippingRates.filter(ft => ft['isDefault']);
    this.shippingRateSelected = tempSRId[0]['id'];
    for (var i = 0; i < this.itemsOrder.length; ++i) {
      if (this.itemsOrder[i]['isSale']) this.itemsOrder[i]['itemTotal'] = Number(this.itemsOrder[i]['salePrice']) * this.itemsOrder[i]['qty'];
      else this.itemsOrder[i]['itemTotal'] = Number(this.itemsOrder[i]['price']) * this.itemsOrder[i]['qty'];
      this.subtotal += this.itemsOrder[i]['itemTotal'];
    }
    this.getTotal(this.shippingRateSelected);
    if (this.currentUser['billingAddress'] == ''){
      this.updateUser = true;
    }
  }

  getTotal(t){
  	let shippingPrice = this.getShippingRate(t);
    // console.log(shippingPrice);
  	this.total = this.subtotal + shippingPrice['value'];
    this.order['shippingType'] = shippingPrice['type'];
    this.order['shippingCost'] = shippingPrice['price'];
  }

  onChangeShippingRate(e){
  	this.getTotal(e.target.value);
  }

  onChangeBillingAddress(e){
    this.setDifferentAddress(e.target.checked);
  }

  setDifferentAddress(e){
    this.currentUser['isDifferent'] = e;
    // console.log(this.currentUser['isDifferent']);
  }

  onChangeShippingState(e){
    this.currentUser['shippingState'] = e.target.value;
  }

  onChangeState(e){
    this.currentUser['billingState'] = e.target.value;
  }

  onChangePayment(e){
    let paymentTypeId = e.target.value;
    // console.log(paymentTypeId);
    if (paymentTypeId != 0){
      this.order['paymentType'] = paymentTypeId;
      this.order['paymentName'] = this.getPaymentBy(paymentTypeId)['name'];
      this.errorMessageOrder = '';
      $('.payment-type .c-radio').removeClass('has-error');
    }else{
      this.errorMessageOrder = 'Select Payment Type';
      $('.payment-type .c-radio').addClass('has-error');
    }
  }

  onOrderConfirm(e){
    // console.log(e.target.checked);
    this.orderConfirm = e.target.checked;
    if (this.orderConfirm){
      this.errorMessageOrder = '';
      $('.order-confirm').removeClass('has-error');
    }else{
      this.errorMessageOrder = 'Please read and accept the Terms and Conditions';
      $('.order-confirm').addClass('has-error');
    }
  }



  onSubmitOrder(){
    let billingFirstName : string = (<HTMLInputElement>document.getElementById("billing-firstname")).value;
    let billingLastName : string = (<HTMLInputElement>document.getElementById("billing-lastname")).value;
    let billingCompany : string = (<HTMLInputElement>document.getElementById("billing-company")).value;
    let billingAddress : string = (<HTMLInputElement>document.getElementById("billing-address")).value;
    let billingCity : string = (<HTMLInputElement>document.getElementById("billing-city")).value;
    let billingZipcode : string = (<HTMLInputElement>document.getElementById("billing-zipcode")).value;

  	if (billingFirstName == ''){
      this.errorMessageBillingAddress = 'Enter Billing First Name';
      $('.billing-address').addClass('has-error');
      return;
    }else{
      this.errorMessageBillingAddress = '';
      $('.billing-address').removeClass('has-error');
      this.currentUser['firstname'] =  billingFirstName;
      this.order['billingFirstname'] =  billingFirstName;
    }
    if (billingLastName == ''){
      this.errorMessageBillingAddress = 'Enter Billing Last Name';
      $('.billing-address').addClass('has-error');
      return;
    }else{
      this.errorMessageBillingAddress = '';
      $('.billing-address').removeClass('has-error');
      this.currentUser['lastname'] =  billingLastName;
      this.order['billingLastname'] =  billingLastName;
    }
  	if (billingAddress == ''){
      this.errorMessageBillingAddress = 'Enter Billing Street Address';
      $('.billing-address').addClass('has-error');
      return;
    }else{
      this.errorMessageBillingAddress = '';
      $('.billing-address').removeClass('has-error');
      this.currentUser['billingAddress'] =  billingAddress;
      this.order['billingAddress'] =  billingAddress;
    }
    if (billingCity == ''){
      this.errorMessageBillingAddress = 'Enter City';
      $('.billing-city').addClass('has-error');
      return;
    }else{
      this.errorMessageBillingAddress = '';
      $('.billing-city').removeClass('has-error');
      this.currentUser['billingCity'] =  billingCity;
      this.order['billingCity'] =  billingCity;
    }
    if (this.currentUser['billingState'] == undefined || this.currentUser['billingState'] == ''){ 
      this.errorMessageBillingAddress = 'Select State';
      $('.billing-state').addClass('has-error');
      return;
    }else{
      this.errorMessageBillingAddress = '';
      $('.billing-state').removeClass('has-error');
      this.order['billingState'] =  this.currentUser['billingState'];
    }
    if (billingZipcode == ''){
      this.errorMessageBillingAddress = 'Enter Zip code';
      $('.billing-zipcode').addClass('has-error');
      return;
    }else{
      this.errorMessageBillingAddress = '';
      $('.billing-zipcode').removeClass('has-error');
      this.currentUser['billingZipcode'] = billingZipcode;
      this.order['billingZipcode'] = billingZipcode;
    }
    this.order['shippingCompany'] = billingCompany;

    if (this.currentUser['isDifferent']){
      let shippingFirstName : string = (<HTMLInputElement>document.getElementById("shipping-firstNnme")).value;
      let shippingLastName : string = (<HTMLInputElement>document.getElementById("shipping-lasnName")).value;
      let shippingCompany : string = (<HTMLInputElement>document.getElementById("shipping-company")).value;
      let shippingAddress : string = (<HTMLInputElement>document.getElementById("shipping-address")).value;
      let shippingCity : string = (<HTMLInputElement>document.getElementById("shipping-city")).value;
      let shippingZipcode : string = (<HTMLInputElement>document.getElementById("shipping-zipcode")).value;
      let orderNote : string = (<HTMLInputElement>document.getElementById("order-note")).value;

      if (shippingFirstName == ''){
        this.errorMessageShippingAddress = 'Enter Receiver\'s First Name ';
        $('.shipping-firstname').addClass('has-error');
        return;
      }else{
        this.errorMessageShippingAddress = '';
        $('.shipping-firstname').removeClass('has-error');
        this.currentUser['ShippingFirstname'] =  shippingFirstName;
        this.order['ShippingFirstname'] =  shippingFirstName;
      }
      if (shippingLastName == ''){
        this.errorMessageShippingAddress = 'Enter Receiver\'s Last Name';
        $('.shipping-lastname').addClass('has-error');
        return;
      }else{
        this.errorMessageShippingAddress = '';
        $('.shipping-lastname').removeClass('has-error');
        this.currentUser['ShippingFirstname'] =  shippingLastName;
        this.order['ShippingFirstname'] =  shippingLastName;
      }
      if (shippingAddress == ''){
        this.errorMessageShippingAddress = 'Enter Shipping Street Address';
        $('.shipping-address').addClass('has-error');
        return;
      }else{
        this.errorMessageShippingAddress = '';
        $('.shipping-address').removeClass('has-error');
        this.currentUser['shippingAddress'] =  shippingAddress;
        this.order['shippingAddress'] =  shippingAddress;
      }
      if (shippingCity == ''){
        this.errorMessageShippingAddress = 'Enter Shipping City';
        $('.shipping-city').addClass('has-error');
        return;
      }else{
        this.errorMessageShippingAddress = '';
        $('.shipping-city').removeClass('has-error');
        this.currentUser['shippingCity'] =  shippingCity;
        this.order['shippingCity'] =  shippingCity;
      }
      if (this.currentUser['shippingState'] == undefined || this.currentUser['shippingState'] == ''){ 
        this.errorMessageShippingAddress = 'Select Shipping State';
        $('.shipping-state').addClass('has-error');
        return;
      }else{
        this.errorMessageShippingAddress = '';
        $('.shipping-state').removeClass('has-error');
        this.order['shippingState'] =  this.currentUser['shippingState'];
      }
      if (shippingZipcode == ''){
        this.errorMessageShippingAddress = 'Enter Shipping Zip code';
        $('.shipping-zipcode').addClass('has-error');
        return;
      }else{
        this.errorMessageShippingAddress = '';
        $('.shipping-zipcode').removeClass('has-error');
        this.currentUser['shippingZipcode'] = shippingZipcode;
        this.order['shippingZipcode'] = shippingZipcode;
      }
    }else{
      this.currentUser['shippingAddress'] = this.currentUser['billingAddress'];
      this.currentUser['shippingCity'] = this.currentUser['billingCity'];
      this.currentUser['shippingState'] = this.currentUser['billingState'];
      this.currentUser['shippingZipcode'] = this.currentUser['billingZipcode'];

      this.order['shippingAddress'] = this.currentUser['billingAddress'];
      this.order['shippingCity'] = this.currentUser['billingCity'];
      this.order['shippingState'] = this.currentUser['billingState'];
      this.order['shippingState'] =  this.currentUser['shippingState'];
      this.order['shippingZipcode'] = this.currentUser['billingZipcode'];
      this.order['shippingFirstname'] = this.currentUser['firstname'];
      this.order['shippingLastname'] = this.currentUser['lastname'];
    }
  	// console.log(this.currentUser['isDifferent']);

    if (this.order['paymentType'] == 0){
      this.errorMessageOrder = 'Select Payment Type';
      $('.payment-type .c-radio').addClass('has-error');
      return;
    }else{
      this.errorMessageOrder = '';
      $('.payment-type .c-radio').removeClass('has-error');
    }

    if (!this.orderConfirm){
      this.errorMessageOrder = 'Please read and accept the Terms and Conditions';
      $('.order-confirm').addClass('has-error');
      return;
    }else{
      this.errorMessageOrder = '';
      $('.order-confirm').removeClass('has-error');
    }

    // for (var i = 0; i < this.itemsOrder.length; ++i) {
    //   this.order['items'].push(this.itemsOrder[i]['id']);  
    // }
    this.order['items'] = this.itemsOrder;
    this.order['email'] = this.currentUser['email'];
    this.order['phone'] = this.currentUser['phone'];
    this.order['subtotal'] = this.subtotal;
    this.order['total'] = this.total;
    this.order['datetime'] = this._libs.timeStamp;

    // console.log(this.order);

    if (this.updateUser){
      let tempUser = this._libs.convert2UserModel(this.currentUser);
      this.userService.updateUser(this._libs.convert2OriginalId(this.currentUser['id']), tempUser);
    }

    this.orderService.createOrder(this.order);
    this._libs.removeLocalStorageItem('itemsOrder');
    this._libs.go2Page('order');
  }

  ///PAYMENT TYPE
  getPaymentBy(id){
    let temp = this.paymentTypes.filter(ft => ft['id'] == id);
    return temp[0];
  }

  /// Orders
  getOrdersList(){
    this.orderService.getOrdersList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ id: c.payload.key, ...c.payload.val() }))
      )
  ).subscribe(orders => {
      let temnpOrders = orders.sort(this._libs.dynamicSortByName('datetime'));
      this.orders = temnpOrders.filter(ft => ft['email'] == this.currentUser['email']);
      this._libs.setLocalStorageItem('orders', this.orders);
    });
  }

  /// Shipping Rates
  getShippingRatesList(){
    this.shippingRateService.getShippingRatesList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ id: c.payload.key, ...c.payload.val() }))
      )
    ).subscribe(shippingRates => {
      this.shippingRates = shippingRates.sort(this._libs.dynamicSortByName('value'));
      this._libs.setLocalStorageItem('shippingRates', this.shippingRates);
      for (var i = 0; i < this.shippingRates.length; ++i) {
        this.shippingRates[i]['isDefault'] = false;
        if (i == 0){
          this.shippingRateSelected = this.shippingRates[i]['id'];
          this.shippingRates[i]['isDefault'] = true;
        }
      }
      this.step1();      
      // console.log(this.shippingRates);
    });
  }

  getShippingRate(id){
    // console.log(id);
    this.shippingRateSelected = id;
    let temp = this.shippingRates.filter(shippingRate => shippingRate['id'] == id);
    return temp[0];
  }
}
