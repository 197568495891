import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Http, Response, Headers, RequestOptions, URLSearchParams } from '@angular/http';

import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { AngularFirestore, AngularFirestoreDocument } from 'angularfire2/firestore';
import { AngularFireDatabaseModule, AngularFireDatabase, AngularFireList, AngularFireObject } from 'angularfire2/database';
import * as firebase from 'firebase/app';

import { WishListModel } from './wishlist.model';

@Injectable({
  providedIn: 'root'
})
export class WishlistService {

  wishlistesRef: AngularFireList<WishListModel> = null;

  constructor(
  	private afData: AngularFireDatabase,
  ) {
  	this.wishlistesRef = afData.list('/wishlistes');
  }

  getWishlistesList(): AngularFireList<WishListModel> {
    return this.wishlistesRef;
  }

  getWishlist(id): AngularFireList<WishListModel> {
    return this.afData.list('/wishlistes/'+id);
  }

  updateWishlist(key, dev) {
    this.wishlistesRef.set(key, dev);
  }

  createWishlist(wishlistes: WishListModel): void {
    this.wishlistesRef.push(wishlistes);
  }

  deleteWishlist(key: string): void {
    this.wishlistesRef.remove(key).catch(error => this.handleError(error));
  }

  private handleError(error) {
    console.log(error);
  }
}
