import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';
import { EmailModel } from '../_services/email.model';

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  emailRef: AngularFireList<EmailModel> = null;

  constructor(
  	private afData: AngularFireDatabase,
  ) {
  	this.emailRef = afData.list('/emails');
  }

  getEmailsList(): AngularFireList<EmailModel> {
    return this.emailRef;
  }

  getEmail(id): AngularFireList<EmailModel> {
    return this.afData.list('/emails/'+id);
  }

  updateEmail(key, values) {
    this.emailRef.set(key, values);
  }

  createEmail(emails: EmailModel): void {
    // console.log(emails);
    this.emailRef.push(emails);
  }

  deleteEmail(key: string): void {
    this.emailRef.remove(key).catch(error => this.handleError(error));
  }

  private handleError(error) {
    console.log(error);
  }
}
