export class MediaModel {
  name: string;
  imgCode: string;
  // category: string;
  size: number;
  type: string;

  constructor(){
    this.name = "";
    this.imgCode = "";
    // this.category = "";
    this.size = 0;
    this.type = "";
  }
}
