import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-solutions',
  templateUrl: './solutions.component.html',
  styleUrls: ['./solutions.component.scss']
})
export class SolutionsComponent implements OnInit {
  
  solutions = [
  	{id: 1, name:'IP Phone Systems', icon:'product_nav_ipphones.png', img: 'ipphonesystem.jpg', text: 'A VoIP phone system uses IP technology to handle your call control and manage your telephony connections. Even though a VoIP phone system uses VoIP and is connected to your LAN, most systems can connect directly to the Publicly Switched Telephone Network (PSTN). This gives you the ability to use both VoIP and the PSTN for your calling.'},
  	{id: 2, name:'Mobility', icon:'solutions_visualnav_mobility.png', img: 'mobility.jpg', text: 'Technology has made society incredibly mobile, and that mobility has now transferred into the workplace.'},
  	{id: 3, name:'Call Centers', icon:'solution_headline_contactcenters.png', img: 'callcenter.jpg', text: 'Your business may not be running a "call center," but you may find that you need similar enterprise class functionality for your internal contact center. Your inside sales team or tech support groups need to be able to route calls, manage call queues, measure call times, record calls and get on-demand reporting on agent performance. See why a UC system can help you effectively manage your day to day operations for internal contact centers.'},
  	{id: 4, name:'Customer Experience', icon:'solution_headline_customerexperience.png', img: 'customerexperience.jpg', text: 'A VoIP phone system uses IP technology to handle your call control and manage your telephony connections. Even though a VoIP phone system uses VoIP and is connected to your LAN, most systems can connect directly to the Publicly Switched Telephone Network (PSTN). This gives you the ability to use both VoIP and the PSTN for your calling.'},
  	{id: 5, name:'Industry', icon:'solutions_visualnav_industry_orange.png', img: 'industry.jpg', text: 'Automobile Dealerships, Education, Government, Healthcare, Professional Services'},
  	{id: 6, name:'Company Size', icon:'solution_headline_companysize.png', img: 'companysize.jpg', text: 'Looking for a business phone system that has enterprise class features your business can afford? Whether you have five employees or 500, see which UC solution is right for your office communications. You\'ll find that all features are included in each of the Switchvox solutions, so you can choose the premises-based or cloud solution that is right size for your business.'},
  ];

  constructor() { }

  ngOnInit() {
    $('header').removeClass('c-layout-header-2 c-layout-header-default c-header-transparent-dark').addClass('c-layout-header-4');
  }

  scrollToElement($element): void {
    $('.c-content-product-2').removeClass('c-padding-t-150');
    $('.c-solution-'+$element).addClass('c-padding-t-150');
    let id = document.getElementById('sol-'+$element);
    // console.log(id);
    id.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }

  readmore(id){
    // console.log(id);
    $('.c-solution-'+id).removeClass('c-padding-t-150');

  }

}