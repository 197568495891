import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, Params } from '@angular/router';
declare var $: any;

import { LibsService, LocalData } from '../../_services'

@Component({
  selector: 'app-dashboard-menu',
  templateUrl: './dashboard-menu.component.html',
  styleUrls: ['./dashboard-menu.component.scss']
})
export class DashboardMenuComponent implements OnInit {
  menus = this._localDB.dashboardMenu;
  isLogged = false;
  selectedMenu = {};
  menuKey = 'account';
  currentRoute = '';

  constructor(
    public router: Router,
    private _localDB: LocalData,
    private _libs: LibsService,
  ) { 
  }

  ngOnInit() {
    // this.router.events.subscribe(event => {
    //   if (event instanceof NavigationEnd ) {
    //     console.log(event.url);
    //     this.currentRoute = this._libs.getCurrentRoute(event.url);
    //     this.menuKey = this.currentRoute;
    //     console.log(this.currentRoute);
    //     console.log(this.menuKey);
    //   }
    // });
    // console.log(this.menus);
    var urlArr = (window.location.href).split('/#/');
    this.menuKey = urlArr[1];
    // console.log(this.menuKey);
  }

  ngAfterContentInit() {
  	// console.log(this.currentRoute);
    // if (this.currentRoute == 'account'){
    //   this.menuId = 1;
    //   console.log('account'+ this.menuId);
    // }
    // if (this.currentRoute == 'order'){
    //   this.menuId = 2;
    //   console.log('order'+ this.menuId);
    // }
    // if (this.currentRoute == 'wishlist'){
    //   this.menuId = 3;
    //   console.log('wishlist'+ this.menuId);
    // }
    // if (this.currentRoute == 'settings'){
    //   this.menuId = 4;
    //   console.log('settings'+ this.menuId);
    // }
    // console.log('ngOnInit'+ this.menuId);
    // this.getMenuBy(this.menuId);
  }

  getMenuBy(key){
    let temp = this.menus.filter(ft => ft['key'] == key);
    this.selectedMenu = temp[0];
    // console.log(this.selectedMenu['name']);
    $('.menu-name').html(this.selectedMenu['name']);
  }

  onSignout(){
    this._libs.removeLocalStorageItem('currentUser'); //clearLocalStorage();
    this.isLogged = false;
    $('header').addClass('c-layout-header-2').removeClass('c-layout-header-4');
    $('body').removeClass('c-layout-header-fullscreen dashboard favorite savesearch openhouse transactions').addClass('c-bg-grey');
    $('.c-topbar').addClass('c-topbar-light').removeClass('c-topbar-dark');
    $('.c-cart-number, #c-cart-menu').addClass('hide');
    $('.sign-in').html('<a href="javascript:;" data-toggle="modal" data-target="#login-form" class="c-btn btn-no-focus c-btn-header btn btn-sm c-btn-border-1x c-btn-login c-btn-square c-btn-uppercase c-btn-sbold">\
	                        <i class="icon-user"></i> Sign In\
	                    </a>')
    this._libs.go2Page('/');
  }
}
