import { Component, OnInit } from '@angular/core';
import { LocalData, LibsService, MysqlService, PagerService,
   	     EmailModel, EmailService,
         AuthenticationService,
           } from '../_services';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';
declare var $: any;

@Component({
  selector: 'app-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.css']
})
export class AboutusComponent implements OnInit {

  constructor(
    private _libs: LibsService,
  ) { }

  ngOnInit() {
  	$('header').removeClass('c-layout-header-2 c-layout-header-default c-header-transparent-dark').addClass('c-layout-header-4');
    $('.c-topbar').removeClass('c-topbar-dark').addClass('c-topbar-light');
    $('.c-layout-header-fixed').removeClass('c-mega-menu-shown');
    $('.c-mega-menu').removeClass('c-shown');
  }

}
