export class PriceRangeModel {
  min: number;
  max: number;
  name: string;
  shortName: string;
  order: number;

  constructor(){
    this.min = 0;
    this.max = 0;
    this.name = "";
    this.shortName = "";
    this.order = 0;
  }
}
