import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';
import * as $ from 'jquery';

import { LibsService, LocalData, md5, AuthenticationService,
          UserModel, UserService,
          OrderModel, OrderService,
       } from '../../_services'

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.css']
})
export class OrderComponent implements OnInit {
  // menus = this._localDB.dashboardMenu;
  // menuId = 2;
  // selectedMenu = {};
  currentUser = new UserModel;
  isLogged = false;
  orders = [];
  uploadUrl = this._localDB.uploadUrl;

  constructor(
  	private userService: UserService,
  	private orderService: OrderService,
    private _libs: LibsService,
    private _localDB: LocalData,
    private authService: AuthenticationService,
  ) { 
  	this.currentUser = this._libs.getLocalStorageItem('currentUser', 'object');
  	this.orders = this._libs.getLocalStorageItem('orders', 'array');
  }

  ngOnInit() {
  	if (this.currentUser['email'] == undefined){
      this.isLogged = false;
      this._libs.go2Page('/home');
    }else{
      // this.selectedMenu = this.getMenuBy(this.menuId);
      this.isLogged = true;
      if (this.currentUser['isDifferent'] == undefined) this.currentUser['isDifferent'] = false;
      // console.log(this.orders);
      if (this.orders.length == 0){
      	this.getOrdersList();
      }

      $('header').addClass('c-layout-header-4').removeClass('c-layout-header-2');
      $('body').removeClass('c-layout-header-fullscreen dashboard favorite savesearch openhouse transactions').addClass('c-bg-grey');
      $('.c-topbar').addClass('c-topbar-light').removeClass('c-topbar-dark');
    }
  }

  ngAfterContentInit() {
  }

  // getMenuBy(menuId){
  //   let temp = this.menus.filter(ft => ft['id'] == menuId);
  //   return temp[0];
  // }

  // onSignout(){
  //   this._libs.clearLocalStorage();
  //   this.isLogged = false;
  //   $('header').addClass('c-layout-header-4').removeClass('c-layout-header-2');
  //   $('body').removeClass('c-layout-header-fullscreen dashboard favorite savesearch openhouse transactions').addClass('c-bg-grey');
  //   $('.c-topbar').addClass('c-topbar-light').removeClass('c-topbar-dark');
  //   $('.c-cart-number, #c-cart-menu').addClass('hide');
  //   this._libs.go2Page('/');
  // }

  getOrdersList(){
  	this.orderService.getOrdersList().snapshotChanges().pipe(
  		map(changes =>
  			changes.map(c => ({ id: c.payload.key, ...c.payload.val() }))
  		)
	).subscribe(orders => {
      let temnpOrders = orders.sort(this._libs.dynamicSortByNameSort('datetime', 'asc'));
      this.orders = temnpOrders.filter(ft => ft['email'] == this.currentUser['email']);
      for (var i = 0; i < this.orders.length; ++i) {
      	this.orders[i]['date'] = this._libs.convertTimestamp2Date(this.orders[i]['datetime']);
      }
      this._libs.setLocalStorageItem('orders', this.orders);
    });
  }

}
