import { Component, OnInit } from '@angular/core';

import { LocalData, LibsService, MysqlService,
   	     EmailModel, EmailService, 
         AuthenticationService,
           } from '../_services';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';
declare var $: any;

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  resultMessage = '';
  errorMessage = '';

  isValidName = true;
  isValidEmail = true;
  isValidType = true;
  isValidPhone = true;

  contactTypes = [];
  emailContactType = '';

  constructor(
    private authenticationService: AuthenticationService,
    private emailService: EmailService,
    private _libs: LibsService,
    private _localDB: LocalData,
    private _mysql: MysqlService,
  ) { 
    this.contactTypes = this._localDB.contactTypes;
  }

  ngOnInit() {
    $('header').removeClass('c-layout-header-2 c-layout-header-default c-header-transparent-dark').addClass('c-layout-header-4');
    $('.c-topbar').removeClass('c-topbar-dark').addClass('c-topbar-light');
    $('.c-layout-header-fixed').removeClass('c-mega-menu-shown');
    $('.c-mega-menu').removeClass('c-shown');
  }

  emailType(e){
    // console.log(e);
    this.emailContactType = e;
  }

  ///SEND EMAIL
  sendEmail(){
  	let name : string = (<HTMLInputElement>document.getElementById("name")).value; 
    let email : string = (<HTMLInputElement>document.getElementById("email")).value; 
    let phone : string = (<HTMLInputElement>document.getElementById("phone")).value; 
    let message : string = (<HTMLInputElement>document.getElementById("message")).value; 
    if (name == ''){
      this.isValidName = false;
      return;
    }else{
      this.isValidName = true;
    }
    if (email == ''){
      this.isValidEmail = false;
      return;
    }else{
      // console.log(this._libs.isEmail(email));
      if (!this._libs.isEmail(email)){
        (<HTMLInputElement>document.getElementById("email")).value = '';
        this.errorMessage = this._libs.errorEmail;
        this.isValidEmail = false
        return;
      }
      this.isValidEmail = true;
    }
    if (this.emailContactType == ''){
      this.isValidType = false;
      return;
    }else{
      this.isValidType = true;
    }
    if (phone == ''){
      this.isValidPhone = false;
      return;
    }else{
      this.isValidPhone = true;
    }
    if (message == ''){
      message = 'Texan\'s House Contact';
    }

    var params = {
      subject: 'Email from Texan\'s House TX Contact Page',
      email: email,
      type: 'Contact',
      name: name,
      phone: phone,
      message: message,
      address: '',
      option: this.emailContactType,
    }
    this.sendEmailContact(params);
  }

  sendEmailContact(params){
    this._mysql.emailApp(params).subscribe(res => {
      // let result = res;
      if (res['data']['result'] == 'OK'){
        this.resultMessage = res['data']['message'];
        let user = (this._localDB.db['user']).replace('dothang', 'dothang80');
        let pass = (this._localDB.db['pwrd']).replace('ih','HI');
        this.authenticationService.doEmailLogin(user, pass)
        .then(user => {
          var email: EmailModel = new EmailModel;
          email['subject'] = params['subject'];
          email['email'] = params['email'];
          email['type'] = 1;
          email['name'] = params['name'];
          email['phone'] = params['phone'];
          email['message'] = params['message'];
          email['address'] = params['address'];
          email['option'] = params['option'];
          email['datetime'] = this._libs.timeStamp;
          this.emailService.createEmail(email);
          this.authenticationService.doSignOut(false);
        }, err => {
          console.log(err);
        });
      }
    });
  }
}
