import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Validators, FormBuilder, FormGroup, FormControl } from "@angular/forms";
import { ActivatedRoute } from '@angular/router';
import { Http, Response, Headers } from '@angular/http';
import { OwlCarousel } from 'ngx-owl-carousel';
import { Lightbox } from 'angular2-lightbox';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// declare var $: any;
import * as $ from 'jquery';



import { MediaService, MediaModel, 
	       CategoryService, CategoryModel,
	       PagerService, MysqlService, LocalData, LibsService } from '../_services';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  ulInfo = {};
  urlsImg = '';
  uploadUrl = this._localDB.uploadUrl;

	@ViewChild('owlElement') owlElement: OwlCarousel;
	isMobile = false;
	screenWidth = 0;

	customers = [];
	medias = [];
	categories = [];
	selectedCatgory = '';
	isCustomersLoaded =  false;
  filter: any;
  featureItems = [];

	sliderOptions = { margin: 20, 
                    dots: false, 
                    navigation: true, 
                    nav: false, 
                    navText:['<', '>'], 
                    autoplay: true, 
                    loop: true, 
                    lazyLoad: true, 
                    responsive:{ 
                      0:{ 
                        items: 1,
                        nav: true,
                        loop: false 
                      }, 600:{ 
                        items: 2,
                        nav: true,
                        loop: false 
                      }, 1000:{ 
                        items: 3,
                        nav: true,
                        loop: false 
                      }, 1500: {
                        items: 4,
                        nav: true,
                        loop: false
                      } 
                    } 
                  };
	
  constructor(
	  private _libs: LibsService,
    private _localDB: LocalData,
    private _mysql: MysqlService,
    private categoryService: CategoryService,
    private mediaService: MediaService,
  ) {
    this.customers = this._libs.getLocalStorageItem('customers', 'array');
    this.filter = this._libs.getLocalStorageItem('filter', 'array');
    this.featureItems = this._libs.getLocalStorageItem('featureItems', 'array');
  }

  ngOnInit() {

    if (this.filter['types'] == undefined || this.filter['types'].length == 0){
      this._libs.setLocalStorageItem('filter', this.filter);
    }

  	this._libs.enablePageScroll(true);
  	
    $('body').addClass('c-layout-header-fullscreen').removeClass('c-bg-grey');
    $.getScript('../../../assets/js/scripts/revo-slider/slider-default.js');
    $.getScript('../../../assets/js/scripts/pages/extended-portfolio.js');
    
    this.screenWidth = (window.screen.width);
    if (this.screenWidth < 992){
    	this.isMobile = true;
    }else{
      $('.c-topbar').removeClass('c-topbar-light').addClass('c-topbar-dark');
    }
  }

  sanitizerUrl(value){
    return this._libs.getSantizeUrl(value);
  }

  getMedia(id){
    for (var i = 0; i < this.medias.length; ++i) {
      if (this.medias[i]['id'] == id){
        return this.medias[i];
      }
    }
  }

  ///CATEGORY
  getCategoriesList(){
    this.categoryService.getCategoriesList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ 'id': c.payload.key, ...c.payload.val() }))
      )
    ).subscribe(categories => {
      this.categories = categories.filter(category => category['type'] == '1');
    });
  }

}
