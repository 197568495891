import { Component, OnInit } from '@angular/core';
import { LibsService, LocalData, CompanyModel } from '../_services';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  uploadUrl = this._localDB.uploadUrl;
  company: CompanyModel = new CompanyModel();
  menus = this._localDB.menus;
  

  constructor(
    private _libs: LibsService,
    private _localDB: LocalData,
  ) { 
    this.company = this._libs.getLocalStorageItem('company', 'object');
  }

  ngOnInit() {
  }

}
