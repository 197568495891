import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { LocalData, LibsService,  MysqlService, PagerService, md5,
         PriceRangeModel, PricerangeService,
         MediaService, MediaModel,
         ProductModel, ProductService, 
         UserModel, UserService,
         VideoModel, VideoService,
         CategoryService, CategoryModel,
         } from '../../_services';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css']
})
export class CartComponent implements OnInit {
  itemsOrder = [];
  uploadUrl = this._localDB.uploadUrl;
  total = '';
  totalWithShipping = '';

  constructor(
    private _localDB: LocalData,
    private _libs: LibsService,
  ) {
    this.itemsOrder = this._libs.getLocalStorageItem('itemsOrder', 'array');
  }

  ngOnInit() {
  	this.total = (this._libs.formatNumberWithXDecimal(2, this.getTotalPrice())).toString();
  	this.totalWithShipping = (this._libs.formatNumberWithXDecimal(2, this.getTotalPrice() + 15)).toString();
  }

  getTotalPrice(){
  	let temp = 0;
  	for (var i = 0; i < this.itemsOrder.length; ++i) {
  		if (this.itemsOrder[i]['isSale']){
  			temp += Number(this.itemsOrder[i]['salePrice']) * Number(this.itemsOrder[i]['qty']);
  		}else{
  			temp += Number(this.itemsOrder[i]['price']) * Number(this.itemsOrder[i]['qty']);
  		}
  	}
  	// console.log(temp);
  	return temp;
  }

}
