import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, Params } from '@angular/router';
import { LibsService, LocalData, CompanyModel } from '../_services';
import * as $ from 'jquery';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  // uploadUrl = this._localDB.uploadUrl;
  company_logo = "../assets/imgs/logos/logo-w.png";
  company: CompanyModel = new CompanyModel();
  menus = this._localDB.menus;
  itemsOrder = [];
  currentRoute = '';
  screenWidth = 0;

  constructor(
    public router: Router,
    private _libs: LibsService,
    private _localDB: LocalData,
  ) { 
    this.company = this._libs.getLocalStorageItem('company', 'object');
    this.itemsOrder = this._libs.getLocalStorageItem('itemsOrder', 'array');
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd ) {
        this.currentRoute = this._libs.getCurrentRoute(event.url);
        // console.log(this.currentRoute);
        if (this.currentRoute != 'home' && this.currentRoute != ''){
          this.company_logo = "../assets/imgs/logos/logo.png";
        }
      }
    });
  }

  ngOnInit() {
    this.screenWidth = (window.screen.width);
    if (this.screenWidth<992){
    // console.log(this.screenWidth);
      $('.c-topbar').removeClass('c-topbar-dark').addClass('c-topbar-light');
    }else{
      if (this.currentRoute == 'home' || this.currentRoute == ''){
        $('.c-topbar').addClass('c-topbar-dark').removeClass('c-topbar-light');
      }
    }

    $('.c-hor-nav-toggler').click(function(){
      if ($('body').hasClass('c-mega-menu-shown')){
        $('body').removeClass('c-mega-menu-shown');
        $('.c-mega-menu').removeClass('c-shown');
      }else{
        $('.c-layout-header-fixed').addClass('c-mega-menu-shown');
        $('.c-mega-menu').addClass('c-shown');
      }
    });

    $('.c-topbar-toggler').click(function(){
      if ($('body').hasClass('c-topbar-expanded')){
        $('body').removeClass('c-topbar-expanded');
        // $('.c-mega-menu').removeClass('c-shown');
      }else{
        $('body').addClass('c-topbar-expanded');
        // $('.c-mega-menu').addClass('c-shown');
      }
    })
  }

}
