import { Component, OnInit } from '@angular/core';
import { LocalData, LibsService, MysqlService, PagerService,
   	     EmailModel, EmailService,
         AuthenticationService,
           } from '../_services';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';
declare var $: any;

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit {
  
  supports = [
    {id: 1, name: 'Resources', text: 'Find the latest tools and documents for your products', icon: 'fa fa-cog'},
    {id: 2, name: 'Firmware', text: 'Find firmware for your products', icon: 'fa fa-cogs'},
    {id: 3, name: 'FAQ', text: 'Find answers to all your questions', icon: 'fa fa-question'},
    {id: 4, name: 'Tools', text: 'Useful tools for our products', icon: 'fa fa-wrench'},
    {id: 5, name: 'Helpdesk', text: 'Submit and manage your tickets', icon: 'fa fa-volume-control-phone'},
    {id: 6, name: 'Forum', text: 'Get help from the community', icon: 'fa fa-comments-o'},
    {id: 7, name: 'Product Archive', text: 'Our Product Archive', icon: 'fa fa-cubes'},
  ]

  constructor(
    private _libs: LibsService,

  ) { }

  ngOnInit() {
  	$('header').removeClass('c-layout-header-2 c-layout-header-default c-header-transparent-dark').addClass('c-layout-header-4');
    $('.c-topbar').removeClass('c-topbar-dark').addClass('c-topbar-light');
    $('.c-layout-header-fixed').removeClass('c-mega-menu-shown');
    $('.c-mega-menu').removeClass('c-shown');
  }

}
