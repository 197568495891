import { Component, OnInit } from '@angular/core';

import { LibsService, LocalData, md5, AuthenticationService,
          UserModel, UserService,
       } from '../../_services'
import * as $ from 'jquery';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})
export class AccountComponent implements OnInit {
  // menus = this._localDB.dashboardMenu;
  // menuId = 1;
  // selectedMenu = {};
  user: any;
  userOri = new UserModel;
  isLogged = false;

  isValidFirstName = true;
  isValidLastName = true;
  isValidEmail = true;
  isValidPhone = true;
  isSave = false;
  isUpdate = false;
  errorMessage = '';
  errorMessageAddress = '';
  isBillingDifferent = 'No';

  profileFields = [
    {id: 1, name: 'First Name', code: 'firstname', isDif: false, isDisabled: false, maxLength:30},
    {id: 2, name: 'Last Name', code: 'lastname', isDif: false, isDisabled: false, maxLength:20},
    {id: 3, name: 'Email', code: 'email', isDif: false, isDisabled: true, maxLength:50},
    {id: 4, name: 'Phone', code: 'phone', isDif: false, isDisabled: false, maxLength:10},
  ];

  passwordFields = [
    {id: 1, name: 'Old Password', code: 'oldPassword'},
    {id: 2, name: 'New Password', code: 'newPassword'},
    {id: 3, name: 'Confirm Password', code: 'confirmPassword'},
  ]

  ashippingAddressFields = [
    {id: 1, name: 'Address', code: 'shippingAddress'},
    {id: 2, name: 'City', code: 'shippingCity'},
    {id: 3, name: 'State', code: 'shippingState'},
    {id: 4, name: 'Zipcode', code: 'shippingZipcode'},
  ]

  emailPreferrences = this._localDB.emailPreferences;
  states = this._localDB.states;

  constructor(
    private userService: UserService,
    private _libs: LibsService,
    private _localDB: LocalData,
    private authService: AuthenticationService,

  ) { 
    this.user = this._libs.getLocalStorageItem('currentUser', 'object');
    this.userOri = this._libs.getLocalStorageItem('currentUser', 'object');
  }

  ngOnInit() {
    if (this.user['email'] == undefined){
      this.isLogged = false;
      this._libs.go2Page('/home');
    }else{
      this.isLogged = true;
      if (this.user['isDifferent'] == undefined) this.user['isDifferent'] = false;
      // this.selectedMenu = this.getMenuBy(this.menuId);
      this.setDifferentAddress(this.user['isDifferent']);
      $('header').addClass('c-layout-header-4').removeClass('c-layout-header-2');
      $('body').removeClass('c-layout-header-fullscreen dashboard favorite savesearch openhouse transactions').addClass('c-bg-grey');
      $('.c-topbar').addClass('c-topbar-light').removeClass('c-topbar-dark');
    }
  }

  // getMenuBy(menuId){
  //   let temp = this.menus.filter(ft => ft['id'] == menuId);
  //   return temp[0];
  // }

  ///PROFILE
  onUpdateAddress(){
    let address : string = (<HTMLInputElement>document.getElementById("address")).value;
    let city : string = (<HTMLInputElement>document.getElementById("city")).value;
    let zipcode : string = (<HTMLInputElement>document.getElementById("zipcode")).value;

    if (address == ''){
      this.errorMessageAddress = 'Enter Street address';
      $('.address').addClass('has-error');
      return;
    }else{
      this.errorMessageAddress = '';
      $('.address').removeClass('has-error');
      this.user['billingAddress'] =  address;
    }
    if (city == ''){
      this.errorMessageAddress = 'Enter City';
      $('.city').addClass('has-error');
      return;
    }else{
      this.errorMessageAddress = '';
      $('.city').removeClass('has-error');
      this.user['billingCity'] = city;
    }
    if (this.user['state'] == undefined || this.user['state'] == ''){ 
      this.errorMessageAddress = 'Select State';
      $('.state').addClass('has-error');
      return;
    }else{
      this.errorMessageAddress = '';
      $('.state').removeClass('has-error');
    }
    if (zipcode == ''){
      this.errorMessageAddress = 'Enter Zip code';
      $('.zipcode').addClass('has-error');
      return;
    }else{
      this.errorMessageAddress = '';
      $('.zipcode').removeClass('has-error');
      this.user['billingZipcode'] = zipcode;
    }

    if (this.isBillingDifferent == 'Yes'){
      let shippingAddress : string = (<HTMLInputElement>document.getElementById("shippingAddress")).value;
      let shippingCity : string = (<HTMLInputElement>document.getElementById("shippingCity")).value;
      let shippingZipcode : string = (<HTMLInputElement>document.getElementById("shippingZipcode")).value;

      if (shippingAddress == ''){
        this.errorMessageAddress = 'Enter Shipping Street address';
        $('.shipping-address').addClass('has-error');
        return;
      }else{
        this.errorMessageAddress = '';
        $('.shipping-address').removeClass('has-error');
        this.user['shippingAddress'] = shippingAddress;
      }
      if (shippingCity == ''){
        this.errorMessageAddress = 'Enter Shipping City';
        $('.shipping-city').addClass('has-error');
        return;
      }else{
        this.errorMessageAddress = '';
        $('.shipping-city').removeClass('has-error');
        this.user['shippingCity'] = shippingCity;
      }
      if (this.user['shippingState'] == undefined || this.user['shippingState'] == ''){ 
        this.errorMessageAddress = 'Select Shipping State';
        $('.shipping-state').addClass('has-error');
        return;
      }else{
        this.errorMessageAddress = '';
        $('.shipping-state').removeClass('has-error');
      }
      if (shippingZipcode == ''){
        this.errorMessageAddress = 'Enter Shipping Zip code';
        $('.shipping-zipcode').addClass('has-error');
        return;
      }else{
        this.errorMessageAddress = '';
        $('.shipping-zipcode').removeClass('has-error');
        this.user['shippingZipcode'] = shippingZipcode;
      }
    }else{
      this.user['shippingAddress'] = address;
      this.user['shippingCity'] = city;
      this.user['shippingState'] = this.user['state'];
      this.user['shippingZipcode'] = zipcode;
    }

    // console.log(this.user);
    this.onUpdateProfile();
  }

  onChangeState(e){
    // console.log(e.target.value);
    this.user['billingState'] = e.target.value;
  }

  onChangeShippingState(e){
    this.user['shippingState'] = e.target.value;
  }

  onChangeBillingAddress(e){
    this.setDifferentAddress(e.target.checked);
  }

  setDifferentAddress(e){
    this.user['isDifferent'] = e;
    if (this.user['isDifferent']){
      this.isBillingDifferent = 'Yes'
    }else{
      this.isBillingDifferent = 'No'
    }
  }

  changeProfile(e, type){
    for (var i = 0; i < this.profileFields.length; ++i) {
    }

    var field = this.profileFields.filter(ft => ft['code'] == type);
    // console.log(this.user[this.profileFields[i]['code']]);
    if(this.user[type] == null || this.user[type] == ''){
      $('.'+type).addClass('has-error');
      return;
    }else{
      if (type == 'phone'){
        var temp = this.user[type].replace(/-/g,'')
        // console.log(temp);
        if (temp.length == this.profileFields[field[0]['id'] - 1]['maxLength']){
          this.user[type] = this.phoneFormat(e);
          // console.log(this.phoneFormat(e));
        }else{
          this.user[type] = temp;
          this.profileFields[field[0]['id'] - 1]['isDif'] = false;
          this.isSave = false;
          $('.'+type).addClass('has-error');
          return;
        }
      }
      var newStr = this.user[type].replace(/ /g,'');
      var oriStr = this.userOri[type].replace(/ /g,'');
      $('.'+type).removeClass('has-error');
      if (oriStr != newStr){
        this.profileFields[field[0]['id'] - 1]['isDif'] = true;
      }else{
        this.profileFields[field[0]['id'] - 1]['isDif'] = false;
      }
    }

    // console.log(this.user);
    // console.log(this.profileFields);
    var iCheck = this.profileFields.filter(ft => ft['isDif'] == true);
    if (iCheck.length > 0){
      this.isSave = true
    }else{
      this.isSave = false;
    }
  }

  phoneFormat(e){
    // console.log(Number(e));
    var temp = this._libs.formatPhoneNumber(e)
    // console.log(temp);
    if (temp != null || temp != ''){
      return temp;
    }
  }

  onUpdateProfile(){
    // console.log(this._libs.convert2UserModel(this.user));
    this.userService.updateUser(this._libs.convert2OriginalId(this.user['id']), this._libs.convert2UserModel(this.user));
    this._libs.setLocalStorageItem('currentUser', this.user);
  }

  ///USER
  getCurrentUser(){
    this.user = this.userService.getUser(this._libs.convert2OriginalId(this.user['id']));
  }

  ///PASSWORD
  onUpdatePassword(){
    let oldPassword : string = (<HTMLInputElement>document.getElementById("oldPassword")).value; 
    let newPassword : string = (<HTMLInputElement>document.getElementById("newPassword")).value; 
    let confirmPassword : string = (<HTMLInputElement>document.getElementById("confirmPassword")).value; 
    console.log(oldPassword);
    if (oldPassword == ''){
      this.errorMessage = 'To change your password please fill in all fields';
      $('.oldPassword').addClass('has-error');
      return;
    }else{
      // console.log(md5(oldPassword));
      if (md5(oldPassword) !== this.user['password']){
        this.errorMessage =  "Old Password is not correct."
        $('.oldPassword').addClass('has-error');
        return;
      }else{
        this.errorMessage = '';
        $('.oldPassword').removeClass('has-error');
      }
    }
    if (newPassword == ''){
      this.errorMessage = 'To change your password please fill in all fields';
      $('.newPassword').addClass('has-error');
      return;
    }else{
      $('.newPassword').removeClass('has-error');
    }
    if (confirmPassword == ''){
      this.errorMessage = 'To change your password please fill in all fields';
      $('.confirmPassword').addClass('has-error');
      return;
    }else{
      if (newPassword !== confirmPassword){
        this.errorMessage =  "New Password and Confirm Password do not match."
        $('.confirmPassword').addClass('has-error');
        return;
      }else{
        this.errorMessage = '';
        $('.confirmPassword').removeClass('has-error');
      }
    }

    // this.updatePassword(oldPassword, newPassword);
  }

  updatePassword(oldPass, newPass){
    this.user['password'] = newPass;
    this.authService.doUpdatePassword(oldPass, newPass)
    .then(res => {
      console.log(res);
      this.user['password'] = md5(oldPass);
      console.log(this.user);
      // this.userService.updateUser(this._libs.convert2OriginalId(this.user['id']), this._libs.convert2UserModel(this.user));
      this.onUpdateProfile();
      (<HTMLInputElement>document.getElementById("oldPassword")).value = '';
      (<HTMLInputElement>document.getElementById("newPassword")).value = '';
      (<HTMLInputElement>document.getElementById("confirmPassword")).value = '';
      this.errorMessage = res.message;
    }, err => {
        // console.log(err);
      this.errorMessage = err.message;
    });
  }

  //EMAIL PREFERRENCE
  onUpdateEmailPreferrence(id){
    // console.log(id);
    this.user['emailPreference'] = id;;
    localStorage.setItem('currentUser', JSON.stringify(this.user));
    this.userService.updateUser(this._libs.convert2OriginalId(this.user['id']), this._libs.convert2UserModel(this.user));
  }

  ///DELETE ACCOUNT
  onDeleteAccount(){

  }

}
