export class ProductModel {
  name: string;
  sku: string;
  price: string;
  description: string;
  overview: string;
  specifications: string;
  image: string;
  categoryId: string;
  active: boolean;
  quantity: string;
  features: any[];
  type: string;
  isSale: boolean;
  salePrice: string;
  isFeature: boolean;
  
  constructor(){
    this.name = "";
    this.sku = "";
    this.description = "";
    this.overview = "";
    this.specifications = "";
    this.image = "";
    this.price = "";
    this.categoryId = "";
    this.active = false;
    this.quantity = '';
    this.features = [];
    this.type = '';
    this.isSale = false;
    this.salePrice = ''
    this.isFeature = false;
  }
}
