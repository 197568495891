import { Component, OnInit, ViewChild, AfterViewInit, ElementRef, NgZone, SecurityContext } from '@angular/core';
import { Http, Headers, RequestOptions, Response, URLSearchParams } from '@angular/http';
import { FormGroup, FormControl, FormBuilder, Validator, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { Router, ActivatedRoute, NavigationEnd, Params } from '@angular/router';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// import { } from '@types/googlemaps';

import { LocalData, LibsService,  MysqlService, PagerService, md5,
         PriceRangeModel, PricerangeService,
         MediaService, MediaModel,
         ProductModel, ProductService, 
         UserModel, UserService,
         VideoModel, VideoService,
         CategoryService, CategoryModel,
         } from '../../_services';

declare var $: any;
declare var google: any;
@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit {
  products = [];
  productId = '';
  product = {};
  uploadUrl = this._localDB.uploadUrl;
  currentUser: UserModel = new UserModel();
  itemsOrder = [];
  itemIds = [];

  constructor(
  	private route: ActivatedRoute,
    private http: Http,
    private pricerangeService: PricerangeService,
    private mediaService: MediaService,
    private categoryService: CategoryService,
    private videoService: VideoService,
    private sanitizer: DomSanitizer,
    private router: Router,
    private _localDB: LocalData,
    private _libs: LibsService,
  ) {
  	this.products = this._libs.getLocalStorageItem('products', 'array');
  	this.currentUser = this._libs.getLocalStorageItem('currentUser', 'object');
    this.itemsOrder = this._libs.getLocalStorageItem('itemsOrder', 'array');

  }

  ngOnInit() {
    $('header').removeClass('c-layout-header-2 c-layout-header-default c-header-transparent-dark').addClass('c-layout-header-4');
  	this.route.params.subscribe((params: Params) => {
      this.productId = params['id'];
      let tempPro = this.products.filter(ft => ft['id'] == this.productId);
      this.product = tempPro[0];
      // console.log(this.product);
  	});
  }

   /// ADD CART
  addItem(itemId){
    // let itemIds = [];
    // console.log(itemId);
    let isAdd = true;
    if (this.itemIds.length == 0){
      this.itemIds.push(itemId);
    }else{
      if (this.itemIds.indexOf(itemId) < 0){
        this.itemIds.push(itemId);
      }else{
        isAdd = false;
      }
    }
    let selectedItems = this.getProductBy(itemId);
    if (isAdd){
      selectedItems['qty'] = 1;
      this.itemsOrder.push(selectedItems);
    }else{
      for (var c = 0; c < this.itemsOrder.length; ++c) {
        if (this.itemsOrder[c]['id'] == itemId){
          this.itemsOrder[c]['qty'] ++;
        }
      }
    }
    console.log(this.itemsOrder);
    let totalItems = 0;
    for (var i = 0; i < this.itemsOrder.length; ++i) {
      totalItems += this.itemsOrder[i]['qty'];
    }
    if (this.itemIds.length > 0){
      this._libs.setLocalStorageItem('itemsOrder', this.itemsOrder);
      document.getElementById("c-cart-number").innerHTML = (totalItems).toString();
      $('.c-cart-number').removeClass('hide');
    }else{
      $('.c-cart-number').addClass('hide');
    }
  }

  ///PRODUCTs
  getProductBy(id){
    var temp = this.products.filter(ft => ft['id'] == id);
    return temp[0];
  }
}
