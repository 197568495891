import { Component, OnInit } from '@angular/core';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Http, Response, Headers, RequestOptions, URLSearchParams } from '@angular/http';

import { AngularFirestore, AngularFirestoreDocument } from 'angularfire2/firestore';
import { AngularFireDatabaseModule, AngularFireDatabase, AngularFireList, AngularFireObject } from 'angularfire2/database';
import * as firebase from 'firebase/app';

import { ServicesModel } from './services.model';

@Injectable({
  providedIn: 'root'
})
export class ServicesService {

  servicesRef: AngularFireList<ServicesModel> = null;

  constructor(
  	private router: Router,
    private afStore: AngularFirestore,
    private afData: AngularFireDatabase,
  ) {
  	this.servicesRef = afData.list('/services');
  }

  getServicesList(): AngularFireList<ServicesModel> {
    return this.servicesRef;
  }

  getService(id): AngularFireList<ServicesModel> {
    return this.afData.list('/services/'+id);
  }

  updateService(key, values) {
    this.servicesRef.set(key, values);
  }

  createService(values: ServicesModel): void {
    this.servicesRef.push(values);
  }

  deleteService(key: string): void {
    this.servicesRef.remove(key).catch(error => this.handleError(error));
  }

  deleteAll(): void {
    this.servicesRef.remove().catch(error => this.handleError(error));
  }
  
  private handleError(error) {
    console.log(error);
  }
  
}
