import { Component, OnInit } from '@angular/core';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Http, Response, Headers, RequestOptions, URLSearchParams } from '@angular/http';

import { AngularFirestore, AngularFirestoreDocument } from 'angularfire2/firestore';
import { AngularFireDatabaseModule, AngularFireDatabase, AngularFireList, AngularFireObject } from 'angularfire2/database';
import * as firebase from 'firebase/app';

import { VideoModel } from './video.model';

@Injectable({
  providedIn: 'root'
})
export class VideoService {
  videoRef: AngularFireList<VideoModel> = null;
  virtualRef: AngularFireList<VideoModel> = null;

  constructor(
    private router: Router,
    private afStore: AngularFirestore,
    private afData: AngularFireDatabase,
  ) {
    this.videoRef = afData.list('/videos');
    this.virtualRef = afData.list('/virtuals');
  }

  getVideosList(): AngularFireList<VideoModel> {
    return this.videoRef;
  }

  getVideo(id): AngularFireList<VideoModel> {
    return this.afData.list('/videos/'+id);
  }

  updateVideo(key, value) {
    this.videoRef.set(key, value);
  }

  createVideo(value: VideoModel): void {
    this.videoRef.push(value);
  }

  deleteVideo(key: string): void {
    this.videoRef.remove(key).catch(error => this.handleError(error));
  }

  deleteAllVideo(): void {
    this.videoRef.remove().catch(error => this.handleError(error));
  }
  
  private handleError(error) {
    console.log(error);
  }

  ///VIRTUAL VIDEO

  getVirtualVideosList(): AngularFireList<VideoModel> {
    return this.virtualRef;
  }

  getVirtualVideo(id): AngularFireList<VideoModel> {
    return this.afData.list('/virtuals/'+id);
  }

  updateVirtualVideo(key, value) {
    this.virtualRef.set(key, value);
  }

  createVirtualVideo(value: VideoModel): void {
    this.virtualRef.push(value);
  }

  deleteVirtualVideo(key: string): void {
    this.virtualRef.remove(key).catch(error => this.handleError(error));
  }

  deleteAllVirtualVideo(): void {
    this.virtualRef.remove().catch(error => this.handleError(error));
  }

}
