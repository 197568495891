export class OrderModel {
  billingFirstname: string;
  billingLastname: string;
  billingAddress: string;
  billingCity: string;
  billingState: string;
  billingZipcode: string;
  email: string;
  phone: string;
  isDifferentAddress: boolean;
  shippingFirstname: string;
  shippingLastname: string;
  shippingCompany: string;
  shippingAddress: string;
  shippingCity: string;
  shippingState: string;
  shippingZipcode: string;
  note: string;
  items: any[];
  paymentType: number;
  paymentName: string;
  total: number;
  subtotal: number;
  shippingType: string;
  shippingCost: number;
  status: string;
  datetime: number;

  constructor(){
    this.billingFirstname = "";
    this.billingLastname = "";
    this.billingAddress = '';
    this.billingCity = '';
    this.billingState = '';
    this.billingZipcode = '';
    this.email = "";
    this.phone = "";
    this.isDifferentAddress = false;
    this.shippingFirstname = '';
    this.shippingLastname = "";
    this.shippingCompany = "";
    this.shippingAddress = '';
    this.shippingCity = '';
    this.shippingState = '';
    this.shippingZipcode = '';
    this.note = "";
    this.items = [];
    this.paymentType = 0;
    this.paymentName = '';
    this.total = 0;
    this.subtotal = 0;
    this.shippingType = '';
    this.shippingCost = 0;
    this.status = 'new';
    this.datetime = 0;
  }
}
