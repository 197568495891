import { Injectable } from '@angular/core';

@Injectable()
export class LocalData {
  urls = 'https://www.dothang.net.com';
  matterportUrl = 'https://my.matterport.com/show/?m=';
  noavatar = './assets/layout/img/avatar.jpg'
  uploadUrl = 'http://upload.doleconnections.com/';
  db = {'user': 'dothang@yahoo.com', 'pwrd': 'dht20180ih@'};

  
  houston = {lat: 29.757096, lng: -95.363805};
  infor = 
            {
              'imgs':      [
                            {"id":731,"img":"731"},
                            {"id":732,"img":"732"},
                            {"id":736,"img":"736"}
                            ],
              'address1':  '12226 Claresholm Dr',
              'address2':  'Tomball, TX 77377',
              'phone1':    '708-248-0129',
              'phone2':    '708-890-4605',
              'fax':       '888-123-4567',
              'name':      'DoLe Connections App',
              'email':     'doleconnections@gmail.com',
              'www':       'www.doleconnections.com',
              'logo':      'logo.png',
              'logoW':     'logo-w.png',
              'logo82':    'logo-82.png',
              'slogoFile': 'logo-62.png',
              'facebook':  'https://www.facebook.com/doleconnections',
              'instagram': 'https://www.instagram.com/doleconnections/?hl=en',
              'twitter':   'https://twitter.com/doleconnections',
              'skype':     'doleconnections',
              'days':      'Open 7 days a week',
              'hours':     'from 9:00 am to 7:00 pm'
            };
  
  dashboardMenu = [
    {id: 1, name: 'My Account',          key: 'account'},
    {id: 2, name: 'Order History',       key: 'order'},
    {id: 3, name: 'My Wishlist',         key: 'wishlist'},
    {id: 4, name: 'Setting',             key: 'setting'},
    {id: 5, name: 'Sign Out',            key: 'signout'},
  ];

  paymentTypes = [
    {id: 1, name: 'Paypal', img: 'paypal.jpg'},
    {id: 2, name: 'Credit or debit card', img: 'credit-cards.jpg'}
  ];

  emailPreferences = [
    {id: 1, name: 'Immediately'},
    {id: 2, name: 'Daily'},
    {id: 3, name: 'Never'},
  ];

  categoryType = [
      {id: 1, 'name': 'general'}, // Other, Products
      {id: 2, 'name': 'profile'},
      {id: 3, 'name': 'company'},
      {id: 4, 'name': 'carriers'},
  ];

  emailType = [
      {id: 1, type: 'contact', name: 'Contact'},
      {id: 2, type: 'lending', name: 'Lending'},
      {id: 3, type: 'tour', name: 'Tour'},
      {id: 4, type: 'register', name: 'Register'},
      {id: 5, type: 'sellyourhome', name: 'Sell Your Home'},
      {id: 6, type: 'sendnew', name: 'Send New'},
  ]

  contactTypes = [
    {id: 1, name: 'What is this regarding?', value: '' },
    {id: 2, name: 'Customer support / feedback', value: 'customer' },
    {id: 3, name: 'Applying', value: 'apply'},
    {id: 4, name: 'Press', value: 'press' },
    {id: 5, name: 'Solutions', value: 'solutions'},
    {id: 6, name: 'Partnerships', value: 'partnerships'},
    {id: 7, name: 'Products', value: 'products'},
    {id: 8, name: 'General questions' , value: 'General questions' },
  ];

  shippingRate = [
    {id: 1, type: 'Flat Rate',       price: 30.00, isDefault: true},
    {id: 2, type: 'Local Delivery',  price: 20.00, isDefault: false},
    {id: 3, type: 'Local Pickup',    price: 0,     isDefault: false},
  ];

  videoType = [
                { id: '1', key: 'video', name: 'Video'},
                { id: '2', key: 'tour', name: 'Virtual Tour'}
              ]
           
  states = [
            { id: '1', name: 'Alabama', code: 'AL'},
            { id: '2', name: 'Alaska', code: 'AK'},
            { id: '3', name: 'Arizona', code: 'AZ'},
            { id: '4', name: 'Arkansas', code: 'AR'},
            { id: '5', name: 'California', code: 'CA'},
            { id: '6', name: 'Colorado', code: 'CO'},
            { id: '7', name: 'Connecticut', code: 'CT'},
            { id: '8', name: 'Delaware', code: 'DE'},
            { id: '9', name: 'Florida', code: 'FL'},
            { id: '10', name: 'Georgia', code: 'GA'},
            { id: '11', name: 'Hawaii', code: 'HI'},
            { id: '12', name: 'Idaho', code: 'ID'},
            { id: '13', name: 'Illinois', code: 'IL'},
            { id: '14', name: 'Indiana', code: 'IN'},
            { id: '15', name: 'Iowa', code: 'IA'},
            { id: '16', name: 'Kansas', code: 'KS'},
            { id: '17', name: 'Kentucky', code: 'KY'},
            { id: '18', name: 'Louisiana', code: 'LA'},
            { id: '19', name: 'Maine', code: 'ME'},
            { id: '20', name: 'Maryland', code: 'MD'},
            { id: '21', name: 'Massachusetts', code: 'MA'},
            { id: '22', name: 'Michigan', code: 'MI'},
            { id: '23', name: 'Minnesota', code: 'MN'},
            { id: '24', name: 'Mississippi', code: 'MS'},
            { id: '25', name: 'Missouri', code: 'MO'},
            { id: '26', name: 'Montana', code: 'MT'},
            { id: '27', name: 'Nebraska', code: 'NE'},
            { id: '28', name: 'Nevada', code: 'NV'},
            { id: '29', name: 'New Hampshire', code: 'NH'},
            { id: '30', name: 'New Jersey', code: 'NJ'},
            { id: '31', name: 'New Mexico', code: 'NM'},
            { id: '32', name: 'New York', code: 'NY'},
            { id: '33', name: 'North Carolina', code: 'NC'},
            { id: '34', name: 'North Dakota', code: 'ND'},
            { id: '35', name: 'Ohio', code: 'OH'},
            { id: '36', name: 'Oklahoma', code: 'OK'},
            { id: '37', name: 'Oregon', code: 'OR'},
            { id: '38', name: 'Pennsylvania', code: 'PA'},
            { id: '39', name: 'Rhode Island', code: 'RI'},
            { id: '40', name: 'South Carolina', code: 'SC'},
            { id: '41', name: 'South Dakota', code: 'SD'},
            { id: '42', name: 'Tennessee', code: 'TN'},
            { id: '43', name: 'Texas', code: 'TX'},
            { id: '44', name: 'Utah', code: 'UT'},
            { id: '45', name: 'Vermont', code: 'VT'},
            { id: '46', name: 'Virginia', code: 'VA'},
            { id: '47', name: 'Washington', code: 'WA'},
            { id: '48', name: 'West Virginia', code: 'WV'},
            { id: '49', name: 'Wisconsin', code: 'WI'},
            { id: '50', name: 'Wyoming', code: 'WY'}
           ]

  menus = [
          { id: 1, 
            name: 'Products',
            key: 'products',
            sub: 
                [{id: 1, name: 'Filter', key: 'filter'},
                {id: 2, name: 'incentives', key: 'incentives'},
                {id: 3, name: 'Look Book', key: 'devslookbook'}]
                  
          },
          { id: 2, 
            name: 'Solutions',
            key: 'solutions',
            sub: []
          },
          { id: 3, 
            name: 'Support', 
            key: 'support', 
            sub: []
          },
          { id: 4, 
            name: 'Partners', 
            key: 'partners', 
            sub: []
          },
          { id: 5, 
            name: 'contact',
            key: 'contact',
            sub: []
          },
          { id: 6, 
            name: 'about',
            key: 'about',
            sub: []
          }
        ]
  submenus = [
          { id: 1, 
            menuid: 1, 
            name: 'development',
            key: 'development',
            sub: [{id: 1, name: 'overview', key: 'overview'},
                  {id: 2, name: 'Floor Plans', key: 'floorplans'},
                  {id: 3, name: 'Site Plan', key: 'siteplan'},
                  {id: 4, name: 'Finishes', key: 'finishes'},
                  {id: 5, name: 'Availability', key: 'availability'},
                  {id: 6, name: 'Features', key: 'features'},
                  {id: 7, name: 'Maps', key: 'maps'},
                  {id: 8, name: 'Look Book', key: 'lookbook'}]
          },
          { id: 2, 
            menuid: 2, 
            name: 'unit', 
            sub: [{id: 1, name: 'overview', key: 'overview'},
                  {id: 2, name: 'property details', key: 'propertydetails'},
                  {id: 3, name: 'neighborhood', key: 'neighborhood'},
                  {id: 4, name: 'payment calculator', key: 'paymentcalculator'},
                  {id: 5, name: 'similar homes', key: 'similarhomes'}]
          },
          { id: 3, 
            menuid: 3, 
            name: 'neighborhood',
            sub: [{id: 1, name: 'overview', key: 'overview'},
                  {id: 2, name: 'map', key: 'map'}]
          }
        ]
}