import { Component, OnInit } from '@angular/core';

import { LocalData, LibsService, MysqlService,
   	     EmailModel, EmailService, 
         AuthenticationService,
           } from '../_services';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';
declare var $: any;

@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.scss']
})
export class PartnersComponent implements OnInit {
  resultMessage = '';
  errorMessage = '';

  isValidName = true;
  isValidEmail = true;
  isValidCompany = true;
  isValidPhone = true;
  isValidAddress = true;

  contactTypes = [];
  emailContactType = '';

  partners = [
  	{id: 1, name: '3cx', image: '3cx.png', url: 'www.3cx.com'},
  	{id: 2, name: 'Asterisk', image: 'asterisk.png', url: 'www.asterisk.org'},
  	{id: 3, name: 'Bicom systems', image: 'bicom.png', url: 'www.bicomsystems.com'},
  	{id: 4, name: 'broadsoft', image: 'broadsoft.png', url: 'www.broadsoft.com'},
  	{id: 5, name: 'cirpack', image: 'cirpack.png', url: 'www.cirpack.com'},
  	{id: 6, name: 'fuze', image: 'fuze.png', url: 'www.fuze.com'},
  	{id: 7, name: 'microsoft', image: 'microsoft.png', url: 'www.microsoft.com'},
  	{id: 8, name: 'ribbon', image: 'ribbon.png', url: 'www.ribboncommunications.com'},
  	{id: 9, name: 'sennheiser', image: 'sennheiser.png', url: 'en-us.sennheiser.com'},
  	{id: 10, name: 'skyswitch', image: 'skyswitch.png', url: 'www.skyswitch.com'},
  	{id: 11, name: 'splicecom', image: 'splicecom.jpeg', url: 'www.splicecom.com'},
  	{id: 12, name: 'swoopdata', image: 'swoopdatacom.png', url: 'www.swoopdata.com'},
  	{id: 13, name: 'swyx', image: 'swyx.png', url: 'www.swyx.com'},
  	{id: 14, name: 'telchemy', image: 'telchemy.png', url: 'www.telchemy.com'},
  	{id: 15, name: 'teles', image: 'teles.png', url: 'www.teles.com'},
  	{id: 16, name: 'unify', image: 'unify.png', url: 'www.unify.com'},
  	{id: 17, name: 'videxio', image: 'videxio.png', url: 'www.videxio.com'},
  	{id: 18, name: 'zoom', image: 'zoom.png', url: 'www.zoom.com'},
  ]
  whys = [
  	{id: 1, text: 'Anti-channel conflict management', img: 'bg-22.jpg'},
  	{id: 2, text: 'Zero tolerance for corruption;implementation of anti-corruption measures; self-investigation and reporting process', img: 'bg-23.jpg'},
  	{id: 3, text: 'Project progress management', img: 'bg-24.jpg'},
  ]

  constructor(
  	private authenticationService: AuthenticationService,
    private emailService: EmailService,
    private _libs: LibsService,
    private _localDB: LocalData,
    private _mysql: MysqlService,
  ) { }

  ngOnInit() {
  	$('header').removeClass('c-layout-header-2 c-layout-header-default c-header-transparent-dark').addClass('c-layout-header-4');
    $('.c-topbar').removeClass('c-topbar-dark').addClass('c-topbar-light');
    $('.c-layout-header-fixed').removeClass('c-mega-menu-shown');
    $('.c-mega-menu').removeClass('c-shown');
  }

  scrollToElement($element): void {
    console.log($element);
    $element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }

  ///SEND EMAIL
  sendEmail(){
  	let name : string = (<HTMLInputElement>document.getElementById("name")).value; 
    let email : string = (<HTMLInputElement>document.getElementById("email")).value; 
    let phone : string = (<HTMLInputElement>document.getElementById("phone")).value; 
    let company : string = (<HTMLInputElement>document.getElementById("company")).value; 
    let address : string = (<HTMLInputElement>document.getElementById("address")).value; 
    let message : string = (<HTMLInputElement>document.getElementById("message")).value; 
    if (name == ''){
      this.isValidName = false;
      return;
    }else{
      this.isValidName = true;
    }
    if (email == ''){
      this.isValidEmail = false;
      return;
    }else{
      // console.log(this._libs.isEmail(email));
      if (!this._libs.isEmail(email)){
        (<HTMLInputElement>document.getElementById("email")).value = '';
        this.errorMessage = this._libs.errorEmail;
        this.isValidEmail = false
        return;
      }
      this.isValidEmail = true;
    }
    // if (this.emailContactType == ''){
    //   this.isValidType = false;
    //   return;
    // }else{
    //   this.isValidType = true;
    // }
    if (phone == ''){
      this.isValidPhone = false;
      return;
    }else{
      this.isValidPhone = true;
    }
    if (message == ''){
      message = 'Texan\'s House Contact';
    }

    var params = {
      subject: 'Become a partner',
      email: email,
      type: 'partner',
      name: name,
      phone: phone,
      company: company,
      message: message,
      address: address,
      option: this.emailContactType,
    }
    this.sendEmailContact(params);
  }

  sendEmailContact(params){
    this._mysql.emailApp(params).subscribe(res => {
      // let result = res;
      if (res['data']['result'] == 'OK'){
        this.resultMessage = res['data']['message'];
        let user = (this._localDB.db['user']).replace('dothang', 'dothang80');
        let pass = (this._localDB.db['pwrd']).replace('ih','HI');
        this.authenticationService.doEmailLogin(user, pass)
        .then(user => {
          var email: EmailModel = new EmailModel;
          email['subject'] = params['subject'];
          email['email'] = params['email'];
          email['type'] = 1;
          email['name'] = params['name'];
          email['phone'] = params['phone'];
          email['message'] = params['message'];
          email['address'] = params['address'];
          email['option'] = params['option'];
          email['datetime'] = this._libs.timeStamp;
          this.emailService.createEmail(email);
          this.authenticationService.doSignOut(false);
        }, err => {
          console.log(err);
        });
      }
    });
  }

}
