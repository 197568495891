import { Component, OnInit } from '@angular/core';
import { LocalData, LibsService, MysqlService, PagerService,
   	     EmailModel, EmailService,
   	     PriceRangeModel, PricerangeService, 
   	     ProductModel, ProductService,
   	     ServicesModel, ServicesService,
   	     CarrierModel, CarrierService,
   	     TypeModel, TypeService,
         UserModel, UserService,
         WishlistService, WishListModel,
         AuthenticationService,
           } from '../_services';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';
declare var $: any;

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {
  
  currentUser: UserModel = new UserModel();
  priceranges = [];
  types = [];
  products = [];
  services = [];
  carriers = [];
  filter = {
    sku: '',
  	types: [],
  	category: '',
  	services: [],
  	carriers: [],
  	prices: []
  };

  pager: any = {}; // pager object
  pagedItems: any[]; // paged items
  pageSize = 10;
  uploadUrl = this._localDB.uploadUrl;
  isCarrier =  false;
  filerLoaded = false;
  itemsOrder = [];
  itemIds = [];
  wishlist = new WishListModel();
  userWishlistes = [];
  isClick = '';

  constructor(
  	private authenticationService: AuthenticationService,
  	private pricerangeService: PricerangeService,
  	private productService: ProductService,
  	private servicesService: ServicesService,
    private carrierService: CarrierService,
  	private wishlistService: WishlistService,
  	private typeService: TypeService,
    private emailService: EmailService,
    private pagerService: PagerService,
    private _libs: LibsService,
    private _localDB: LocalData,
    private _mysql: MysqlService,
  ) { 
  	this.priceranges = this._libs.getLocalStorageItem('priceranges', 'array');
  	this.types = this._libs.getLocalStorageItem('types', 'array');
  	this.services = this._libs.getLocalStorageItem('services', 'array');
  	this.carriers = this._libs.getLocalStorageItem('carriers', 'array');
  	this.currentUser = this._libs.getLocalStorageItem('currentUser', 'object');
    this.itemsOrder = this._libs.getLocalStorageItem('itemsOrder', 'array');
    this.products = this._libs.getLocalStorageItem('products', 'array');
    this.userWishlistes = this._libs.getLocalStorageItem('userWishlistes', 'array');

  }

  ngOnInit() {
  	$('header').removeClass('c-layout-header-2 c-layout-header-default c-header-transparent-dark').addClass('c-layout-header-4');
    $('.c-topbar').removeClass('c-topbar-dark').addClass('c-topbar-light');
    $('.c-layout-header-fixed').removeClass('c-mega-menu-shown');
    $('.c-mega-menu').removeClass('c-shown');
    // console.log(this.currentUser['email']);
    
    if (this.priceranges.length == 0){
    	this.getPriceRangesList();
    }else{
    	if (this.types.length == 0){
    		this.getTypesList();
    	}else{
    		if (this.services.length == 0){
    			this.getServicesList();
    		}else{
    			if (this.carriers.length == 0){
    				this.getCarriersList();
    			}else{
		    		if (this.products.length == 0){
		    			this.getProductsList();
		    		// }else{
        //       this.checkLogin();
            }
    			}
    		}
    	}
    }
  }

  ngAfterViewInit() {
    // console.log('after view init');
    // if (this.currentUser['email'] == undefined){
    //   $('.a-add-cart').removeClass('hide');
    //   $('.btn-add-cart').addClass('hide');
    // }else{
    //   $('.btn-add-cart').removeClass('hide');
    //   $('.a-add-cart').addClass('hide');
    // }
  }

  checkLogin(){
    this.currentUser = this._libs.getLocalStorageItem('currentUser', 'object');
    console.log(this.currentUser['email']);
    if (this.currentUser['email'] == undefined){
      $('a-add-cart').removeClass('hide');
      $('btn-add-cart').addClass('hide');
    }else{
      $('a-add-cart').addClass('hide');
      $('button-add-cart').removeClass('hide');
    }
  }

  resetFilter(){
  	this.filter = {
      sku: '',
  		types: [],
	  	category: '',
	  	services: [],
	  	carriers: [],
	  	prices: []
  	}
    this.filerLoaded = false;
  	this.products = this._libs.getLocalStorageItem('products', 'array');
    this.resetCarriersFilter();
    $('input[name=service]').prop('checked', false);
    $('input[name=price]').prop('checked', false);
    // console.log(this.filter);
  }

  checkFilterTypes(type, isAdd){
  	if (isAdd){
  		if (this.filter['types'].indexOf(type) < 0){
  			this.filter['types'].push(type);
  		}
  	}else{
  		this.filter['types'] = this.filter['types'].filter(ft => ft != type);
  	}
    this.filter['types'] = this.filter['types'].sort();
  }

  returnProductsFromId(ids, tempArr){
  	let temp = [];
  	for (var i = 0; i < tempArr.length; ++i) {
  		if (ids.indexOf(tempArr[i]['id']) >= 0){
  			temp.push(tempArr[i]);
  		}
  	}
  	return temp;
  }

  onChangeFilter(e, t){
    let isAdd = true;
    this.filerLoaded = true;

    if (t == 'asku'){
      this.isCarrier = false;
      let text = e.target.value.toLowerCase();
      // console.log(text);
      if (text == ''){
        this.filter['sku'] = '';
        isAdd = false;
      }else{
        this.filter['sku'] = text;
      }
    }
    if (t == 'category'){
      this.isCarrier = false;
      let id = e.target.value;
      if (id == ''){
        this.filter['category'] = '';
        isAdd = false;
      }else{
        this.filter['category'] = id;
      }
    }
    if (t == 'service'){
      this.isCarrier = false;
      let isChecked = e.target.checked;
      let id = e.target.id;
      if (isChecked){
        if (this.filter['services'].indexOf(id) < 0){
          this.filter['services'].push(id);
        }
      }else{
        this.filter['services'] = this.filter['services'].filter(ft => ft != id);
      }
      if (this.filter['services'].length == 0){
        isAdd = false;
        if (this.filter['types'].indexOf('catrrier') >= 0){
          this.filter['carriers'] = [];
          this.checkFilterTypes('catrrier', isAdd);
          this.resetCarriersFilter();
        }
      }
    }
    if (t == 'catrrier'){
      this.isCarrier = true;
      let isChecked = e.target.checked;
      let id = e.target.id;
      if (isChecked){
        if (this.filter['carriers'].indexOf(id) < 0){
          this.filter['carriers'].push(id);
        }
      }else{
        this.filter['carriers'] = this.filter['carriers'].filter(ft => ft != id);
      }
      this.setTrueFalseCarrier(id, isChecked);
      if (this.filter['carriers'].length == 0){
        isAdd = false;
      }
    }
    if (t == 'price'){
      this.isCarrier = false;
      let isChecked = e.target.checked;
      let id = e.target.id;
      if (isChecked){
        if (this.filter['prices'].indexOf(id) < 0){
          this.filter['prices'].push(id);
        }
      }else{
        this.filter['prices'] = this.filter['prices'].filter(ft => ft != id);
      }
      if (this.filter['prices'].length == 0){
        isAdd = false;
      }
    }
    this.checkFilterTypes(t, isAdd);
    this.changingFilter();
  }

  changingFilter(){
    // console.log(this.filter);
    let tempArr = [];
    let tempProducts = this._libs.getLocalStorageItem('products', 'array');
    let tempTypes = this.filter['types'];
    if (tempTypes[0] == 'category' || tempTypes[0] == 'service' || tempTypes[0] == 'catrrier' || tempTypes[0] == 'price'){
      tempArr = tempProducts;
    }
    if (tempTypes.length > 0){
      for (var m = 0; m < tempTypes.length; ++m) {
        /// SKU
        if (tempTypes[m] == 'asku'){
          let sku = (this.filter['sku']).toLowerCase();
          let tempProSku = [];
          for (var l = 0; l < tempProducts.length; ++l) {
            if (tempProducts[l]['sku'].toLowerCase().indexOf(sku) >= 0){
              tempProSku.push(tempProducts[l]);
            }
          }
          tempArr = tempProSku;
        }
        /// CATEGORY
        if (tempTypes[m] == 'category'){
          let id = this.filter[tempTypes[m]]
          tempArr = tempArr.filter(ft => ft['type'] == id);
        }
        /// PRICE
        if (tempTypes[m] == 'price'){
          let tempPrice = [];
          for (var i = 0; i < this.filter['prices'].length; ++i) {
            tempPrice.push(this.getPriceRangeById(this.filter['prices'][i]));
          }
          tempPrice = tempPrice.sort(this._libs.dynamicSortByName('order'));
          // console.log(tempPrice);
          let min = Number(tempPrice[0]['min']);
          let max = Number(tempPrice[tempPrice.length - 1]['max']);
          // console.log(min);
          // console.log(max);
          let tempProArr = [];
          for (var p = 0; p < tempArr.length; ++p) {
            // console.log('min: '+min);
            // console.log('max: '+max);
            // console.log('price: '+Number(tempArr[p]['price']));
            if (Number(tempArr[p]['price']) > min && Number(tempArr[p]['price']) < max){
              // console.log(tempArr[p]);
              tempProArr.push(tempArr[p]);
            }
          }
          tempArr = tempProArr;
        }
        ///SERVICES
        if (tempTypes[m] == 'service'){
          let ids = this.filter['services'];
          if (ids.length > 0){
            let tempIds = [];
            let tempCarrierIds = [];
            for (var i = 0; i < ids.length; ++i) {
              for (var j = 0; j < tempArr.length; ++j) {
                if (tempArr[j]['features'].indexOf(ids[i]) >= 0){
                  if (tempIds.indexOf(tempArr[j]['id']) < 0){
                    tempIds.push(tempArr[j]['id']);
                  }
                }
              }
              if (!this.isCarrier){
                for (var k = 0; k < this.carriers.length; ++k) {
                  if (this.carriers[k]['features'].indexOf(ids[i]) >= 0){
                    this.carriers[k]['isChecked'] = true;
                    if (tempCarrierIds.indexOf(this.carriers[k]['id']) < 0){
                      tempCarrierIds.push(this.carriers[k]['id']);
                    }
                  }else{
                    this.carriers[k]['isChecked'] = false;
                    tempCarrierIds = tempCarrierIds.filter(ft => ft != ids[i]);
                  }
                }
              }
            }
            // console.log(tempCarrierIds);
            if (tempCarrierIds.length > 0){
              this.checkFilterTypes('catrrier', true);
              this.filter['carriers'] = tempCarrierIds;
            // }else{
              // this.checkFilterTypes('carriers', false);
            }
            tempArr = this.returnProductsFromId(tempIds, tempArr);
          }else{
            this.filter['carriers'] = [];
            this.checkFilterTypes('catrrier', false);
            this.resetCarriersFilter();
          }
        }
        /// CARRIER
        if (tempTypes[m] == 'catrrier'){
          // console.log(this.products);
          // console.log(this.carriers);
        }
        
      }
      if (tempArr.length == 0){
        console.log(tempArr);
      }
    }else{
      tempArr = tempProducts;
      this.resetCarriersFilter();
    }
    this.products = tempArr;
    // console.log(this.products);
  }

  resetCarriersFilter(){
    for (var l = 0; l < this.carriers.length; ++l) {
      this.carriers[l]['isChecked'] = false;
    }
  }

  setTrueFalseCarrier(id, isCheck){
    for (var i = 0; i < this.carriers.length; ++i) {
      if (this.carriers[i]['id'] == id) this.carriers[i]['isChecked'] = isCheck
    }
  }

  geWishListtItemId(id){
    // console.log(id);
    this._libs.setLocalStorageItem('tempId', id);
  }

  ///ADD WISHLIST
  addWishlist(id){
    console.log(id);
    this.userWishlistes = [];
    this.wishlistService.getWishlistesList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ id: c.payload.key, ...c.payload.val() }))
      )
    ).subscribe(wishlistes => {
      this.userWishlistes = wishlistes.filter(ft => ft['email'] == this.currentUser['email']);
      // console.log(this.userWishlistes);
      this._libs.setLocalStorageItem('userWishlistes', this.userWishlistes);
      if (this.isClick != id){
        this.isClick = id;
        this.checkWishlistes(id);
      }else{
        this.isClick = '';
        return;
      }
    });
  }

  /// WISHLISTES
  getWishListesList(){
    this.wishlistService.getWishlistesList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ id: c.payload.key, ...c.payload.val() }))
      )
    ).subscribe(wishlistes => {
      this.userWishlistes = wishlistes.filter(ft => ft['email'] == this.currentUser['email']);
      // console.log(this.userWishlistes);
      this._libs.setLocalStorageItem('userWishlistes', this.userWishlistes);
    });
  }

  checkWishlistes(id){
    console.log(id);
    console.log(this.userWishlistes);
    let isHas = this.userWishlistes.filter(ft => ft['itemId'] == id);
    if (isHas.length > 0){
      console.log(isHas[0]['id']);
      this.wishlistService.deleteWishlist(isHas[0]['id']);
      $('.pro'+id).removeClass('c-btn-red').addClass('btn-default');
    }else{
      this.currentUser = this._libs.getLocalStorageItem('currentUser', 'object');
      // console.log(this.currentUser['email']);
      this.wishlist['email'] = this.currentUser['email'];
      this.wishlist['itemId'] = id;
      $('.pro'+id).addClass('c-btn-red').removeClass('btn-default');
      console.log(this.wishlist);    
      this.wishlistService.createWishlist(this.wishlist);
    }
    // this.userWishlistes = [];
    // this.getWishListesList();
  }

  /// ADD CART
  addItem(itemId){
    // let itemIds = [];
    // console.log(itemId);
    let isAdd = true;
    if (this.itemIds.length == 0){
      this.itemIds.push(itemId);
    }else{
      if (this.itemIds.indexOf(itemId) < 0){
        this.itemIds.push(itemId);
      }else{
        isAdd = false;
      }
    }
    let selectedItems = this.getProductBy(itemId);
    if (isAdd){
      selectedItems['qty'] = 1;
      this.itemsOrder.push(selectedItems);
    }else{
      for (var c = 0; c < this.itemsOrder.length; ++c) {
        if (this.itemsOrder[c]['id'] == itemId){
          this.itemsOrder[c]['qty'] ++;
        }
      }
    }
    console.log(this.itemsOrder);
    // this.itemsOrder = _.groupBy(this.itemIds, function(n) {
    //   return n.id;
    // });
    // this.itemsOrder = _.partition(this.itemIds, 'id');
    // console.log(this.itemsOrder);
    // // if (this.itemIds.length == 0){
    // }else{
    //     this.itemIds.push(itemId);
    //   if (this.itemIds.indexOf(itemId) < 0){
    //   }
    // }
    let totalItems = 0;
    for (var i = 0; i < this.itemsOrder.length; ++i) {
      totalItems += this.itemsOrder[i]['qty'];
    }
    if (this.itemIds.length > 0){
      this._libs.setLocalStorageItem('itemsOrder', this.itemsOrder);
      document.getElementById("c-cart-number").innerHTML = (totalItems).toString();
      $('.c-cart-number').removeClass('hide');
    }else{
      $('.c-cart-number').addClass('hide');
    }
  }

  ///PRODUCTs
  getProductsList(){
    this.productService.getProductsList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ id: c.payload.key, ...c.payload.val() }))
      )
    ).subscribe(products => {
      this.products = products.sort(this._libs.dynamicSortByName('name'));
      for (var i = 0; i < this.products.length; ++i) {
        this.products[i]['isWishList'] = false;  
      }
      this._libs.setLocalStorageItem('products', this.products);
      // console.log(this.products);
      this.setPage(1);
    });
  }

  getProductBy(id){
    var temp = this.products.filter(ft => ft['id'] == id);
    return temp[0];
  }

  /// PRICE
  getPriceRangesList(){
  	this.pricerangeService.getPriceRangesList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ id: c.payload.key, ...c.payload.val() }))
      )
    ).subscribe(priceranges => {
      this.priceranges = priceranges.sort(this._libs.dynamicSortByName('order'));
      this._libs.setLocalStorageItem('priceranges', this.priceranges);
      if (this.types.length == 0){
		this.getTypesList();
	  }else{
		if (this.services.length == 0){
			this.getServicesList();
		}else{
			if (this.carriers.length == 0){
				this.getCarriersList();
			}else{
	    		if (this.products.length == 0){
	    			this.getProductsList();
	    		}
	    	}
		}
	  }
      // console.log(this.priceranges);
    });
  }

  getPriceRangeById(id){
    let price = this.priceranges.filter(price => price['id'] == id);
    return price[0];
  }

  /// PHONE TYPE
  getTypesList(){
  	this.typeService.getTypesList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ id: c.payload.key, ...c.payload.val() }))
      )
    ).subscribe(types => {
      this.types = types.sort(this._libs.dynamicSortByName('name'));
      this._libs.setLocalStorageItem('types', this.types);
      if (this.services.length == 0){
		this.getServicesList();
	  }else{
	  	if (this.carriers.length == 0){
			this.getCarriersList();
		}else{
			if (this.products.length == 0){
				this.getProductsList();
			}
		}
	  }
      // console.log(this.types);
    });
  }

  getType(id){
    var temp = this.types.filter(pt => pt['id'] == id);
    return temp[0];
  }

  /// SERVICES
   getServicesList(){
  	this.servicesService.getServicesList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ id: c.payload.key, ...c.payload.val() }))
      )
    ).subscribe(services => {
      this.services = services.sort(this._libs.dynamicSortByName('name'));
      this._libs.setLocalStorageItem('services', this.services);

      if (this.carriers.length == 0){
  		  this.getCarriersList();
  	  }else{
    		if (this.products.length == 0){
    			this.getProductsList();
    		}
  	  }
      // console.log(this.services);
    });
  }

  getAvailable(id){
    let temp = this.services.filter(service => service['id'] == id);
    return temp[0];
  }

  /// CARRIERS
  getCarriersList(){
  	this.carrierService.getCarriersList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ id: c.payload.key, ...c.payload.val() }))
      )
    ).subscribe(carriers => {
      this.carriers = carriers.sort(this._libs.dynamicSortByName('name'));
      for (var i = 0; i < this.carriers.length; ++i) {
        // let temp = [];
        // for (var j = 0; j < this.carriers[i]['features'].length; ++j) {
        //   temp.push({id: this.carriers[i]['features'][j], isCheck: false});
        // }
        this.carriers[i]['isChecked'] = false;
      }
      this._libs.setLocalStorageItem('carriers', this.carriers);
      // console.log(this.carriers);
      if (this.products.length == 0){
		this.getProductsList();
	  }
    });
  }

  getCarrier(id){
    var temp = this.carriers.filter(dev => dev['id'] == id);
    return temp[0];
  }

  /// PAGER
   setPage(page: number) {
      // get pagerProducts object from service
      this.pager = this.pagerService.getPager(this.products.length, page, this.pageSize);
      // console.log(this.pagerProducts);
      this.pagedItems = this.products.slice(this.pager.startIndex, this.pager.endIndex + 1);
      // console.log(this.pagedItemsProducts);
  }
}
