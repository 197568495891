import { Component, OnInit } from '@angular/core';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Http, Response, Headers, RequestOptions, URLSearchParams } from '@angular/http';

import { AngularFirestore, AngularFirestoreDocument } from 'angularfire2/firestore';
import { AngularFireDatabaseModule, AngularFireDatabase, AngularFireList, AngularFireObject } from 'angularfire2/database';
import * as firebase from 'firebase/app';

import { ShippingRateModel } from './shipping-rate.model';

@Injectable({
  providedIn: 'root'
})
export class ShippingRateService {
  shippingrateRef: AngularFireList<ShippingRateModel> = null;

  constructor(
  	private router: Router,
    private afStore: AngularFirestore,
    private afData: AngularFireDatabase,
  ) {
  	this.shippingrateRef = afData.list('shippingrates');
  }

  getShippingRatesList(): AngularFireList<ShippingRateModel> {
    return this.shippingrateRef;
  }

  getShippingRate(id): AngularFireList<ShippingRateModel> {
    return this.afData.list('shippingrates/'+id);
  }

  updateShippingRate(key, values) {
    this.shippingrateRef.set(key, values);
  }

  createShippingRate(values: ShippingRateModel): void {
    this.shippingrateRef.push(values);
  }

  deleteShippingRate(key: string): void {
    this.shippingrateRef.remove(key).catch(error => this.handleError(error));
  }

  deleteAll(): void {
    this.shippingrateRef.remove().catch(error => this.handleError(error));
  }
  
  private handleError(error) {
    console.log(error);
  }
}
